import React from "react";

import { Loader, Header } from 'semantic-ui-react'

import { groupBy } from '../../utils/helperFunktions.js'

import { translationHelper } from '../../stores/translationDict.js';

import ReactEcharts from "echarts-for-react";
import { useFilteredData } from "../../utils/customHooks.js";
import { filterHelper, filterKeyName, filterKeys } from "../../stores/filterManagement.js";

export const AssetMonitor = () => {

    const [assetMonitor] = useFilteredData('/api/view/monitor', {}, { eventName: ["location", "temperature"] });

    const filterFrom = filterHelper.getFilter(filterKeyName(filterKeys.fromDate));
    const filterTo = filterHelper.getFilter(filterKeyName(filterKeys.toDate));

    const startDate = filterFrom ? new Date(filterFrom) : new Date(new Date().valueOf() - (1000 * 60 * 60));
    const endDate = filterTo ? new Date(filterTo) : new Date(new Date().valueOf() + (1000 * 60 * 5));

    const temperatureData = ([...(assetMonitor.values ?? [])])
        .filter(x => x.eventName === 'temperature')
        .toSorted((a, b) => (a['assetId'] ?? '').localeCompare(b['assetId'] ?? ''));

    const groupedTemperatureData = groupBy(temperatureData, "assetId");

    const temperatureOptions = {
        toolbox: {
            feature: {
                dataZoom: {
                    yAxisIndex: false
                },
                saveAsImage: {
                    pixelRatio: 2
                }
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            bottom: 90
        },
        dataZoom: [
            {
                type: 'inside'
            },
            {
                type: 'slider'
            }
        ],
        xAxis: {
            type: 'time',
            silent: false,
            splitLine: {
                show: false
            },
            splitArea: {
                show: false
            },
            min: startDate,
            max: endDate,
            interval: (1000 * 60 * 5),
        },
        yAxis: {
            splitArea: {
                show: false
            }
        },
    };


    const locationData = [...(assetMonitor.values ?? [])]
        .filter(x => x.eventName === 'location')
        .toSorted((a, b) => (a['assetId'] ?? '').localeCompare(b['assetId'] ?? ''));

    const groupedLocationData = groupBy(locationData, "assetId");

    let colors = {};
    const hashCode = s => s.split('').reduce((a, b) => { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0);
    const locationsGrouped = groupBy(locationData, "locationId");
    Object.keys(locationsGrouped)
        .forEach(locationKey => {
            const zonesGrouped = groupBy(locationsGrouped[locationKey], "zoneId");
            Object.keys(zonesGrouped)
                .forEach(zoneKey => {
                    colors[locationKey] = {
                        ...colors[locationKey],
                        [zoneKey]: "#" + Math.abs(hashCode(zoneKey + locationKey)).toString(16).slice(0, 6)
                    };
                });
        });

    Object.keys(groupedLocationData)
        .forEach(key => {
            groupedLocationData[key] = groupedLocationData[key]
                .toSorted((a, b) =>
                    (a.start?.valueOf() ?? 0) - (b.start?.valueOf() ?? 0)
                )
        });

    const locationOptions = {
        xAxis: {
            type: "time",
            splitLine: {
                show: false
            },
            min: startDate,
            max: endDate,
            interval: (1000 * 60 * 5),
        },
        yAxis: {
            type: "value",
            show: false,
        },
        grid: {
            top: 15,
            left: 15,
            right: 15,
            bottom: 15,
            height: 160
        }
    };

    const allKeys = [...new Set([
        ...Object.keys(groupedTemperatureData),
        ...Object.keys(groupedLocationData)
    ])];

    return (
        <>
            {
                (
                    assetMonitor.processing &&
                    <Loader active>
                        <Header>
                            {translationHelper.translate('waitingForData')}
                        </Header>
                    </Loader>
                )
                ||
                (
                    !allKeys.length &&
                    <Header>
                        {translationHelper.translate('noDataHeader')}
                    </Header>
                )
                ||
                (
                    allKeys.map(key => (
                        <React.Fragment key={key}>
                            <Header textAlign='center'>
                                {key.toString()}
                            </Header>
                            {
                                groupedTemperatureData[key] &&
                                <ReactEcharts
                                    notMerge={true}
                                    option={{
                                        ...temperatureOptions,
                                        series: [
                                            {
                                                type: 'bar',
                                                large: true,
                                                data: [...groupedTemperatureData[key]]
                                                    .sort(function (a, b) {
                                                        if (a.start < b.start) {
                                                            return -1;
                                                        }
                                                        if (a.start > b.start) {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    })
                                                    .map(ele => ([ele["start"], ele["temperature"]]))
                                            }
                                        ]
                                    }}
                                />
                            }
                            {
                                groupedLocationData[key] &&
                                <ReactEcharts
                                    notMerge={true}
                                    option={{
                                        ...locationOptions,
                                        series: [...groupedLocationData[key]]
                                            .map((ele, index) => ({
                                                name: ele.locationName + (ele.zoneName ? ` - ${ele.zoneName}` : ''),
                                                type: "line",
                                                symbol: "none",
                                                symbolSize: 5,
                                                sampling: "average",
                                                areaStyle: {},
                                                itemStyle: {
                                                    color: colors[ele.locationId][ele.zoneId]
                                                },
                                                data: [
                                                    [ele.start, 1],
                                                    [(groupedLocationData[key][index + 1]?.start ?? new Date()), 1],
                                                ]
                                            }))
                                    }}
                                />
                            }
                        </React.Fragment>
                    ))
                )
            }
        </>
    )
}