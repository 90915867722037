import React from "react";
import { useApiGet, useApiPost } from "../../utils/customHooks";
import { TableComp } from "../Layout/TableComp";
import { translationHelper } from '../../stores/translationDict.js';

export const BridgesMasterData = () => {
    const classKeys = [
        "id",
        "name",
        "locationID",
        "locationName",
        "zoneID",
        "zoneName",
        "ownerID"
    ]
        .map(key => ({
            key,
            get name() { return translationHelper.translate(this.key + '_BridgeKey') }
        }));

    const [bridges,, reloadBridges] = useApiGet('/api/wiliot/bridges');
    const [bridgePostState] = useApiPost('/api/wiliot/bridges');
    
    return (
        <TableComp
            classKeys={classKeys}
            dataState={bridges}
            reloadDataFunc={reloadBridges}
            actionState={bridgePostState}
        />
    )
}
