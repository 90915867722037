import React, { useState } from 'react';
import { Form, Message, Header } from 'semantic-ui-react';
import { translationHelper } from '../../stores/translationDict.js';
import { useApiFileExport, useApiGet } from '../../utils/customHooks.js';

export const DataExporter = () => {
    const [startValue, setStart] = useState("");
    const [endValue, setEnd] = useState("");
    const [assetList, setAssetList] = useState([]);
    const [eventList, setEventList] = useState([]);

    const [apiState, exporter] = useApiFileExport('api/data/export/csv');

    const [assets] = useApiGet('/api/wiliot/assets');

    const [eventNames] = useApiGet('/api/db/eventNames');

    const assetOptions = [...(new Set([...(assets.values ?? [])].map(x => x.id)))]
        .toSorted()
        .map(assetId => ({
            key: assetId,
            text: assetId,
            value: assetId
        }));
    const eventOptions = [...(new Set([...(eventNames.values ?? [])]))]
        .toSorted()
        .map(eventName => ({
            key: eventName,
            text: eventName,
            value: eventName
        }));

    const onExport = () => {
        apiState.parameter = {
            ...(startValue?.length > 0 ? { start: startValue } : {}),
            ...(endValue?.length > 0 ? { end: endValue } : {}),
            ...(assetList?.length > 0 ? { assets: assetList } : {}),
            ...(eventList?.length > 0 ? { eventNames: eventList } : {}),
        };

        exporter();
    }

    return (
        <>
            <Form onSubmit={onExport} loading={apiState.processing}>
                <Header>
                    Data Export
                </Header>
                <Form.Input
                    fluid
                    label={translationHelper.translate('fromDatetime')}
                    type="datetime-local"
                    value={startValue}
                    onChange={(_, { value }) => {
                        if (value > endValue && endValue !== "" && value !== "") {
                            setEnd(value)
                        }
                        setStart(value)
                    }}
                />
                <Form.Input
                    fluid
                    label={translationHelper.translate('toDatetime')}
                    type="datetime-local"
                    value={endValue}
                    onChange={(_, { value }) => {
                        if (value < startValue && startValue !== "" && value !== "") {
                            setStart(value)
                        }
                        setEnd(value)
                    }}
                />
                <Form.Dropdown
                    label={translationHelper.translate('assetIdLabel')}
                    placeholder={translationHelper.translate('notFiltered')}
                    fluid
                    multiple
                    search
                    selection
                    loading={assets.processing}
                    disabled={assets.processing}
                    options={assetOptions}
                    onChange={(_, { value }) => setAssetList(value)}
                />
                <Form.Dropdown
                    label={translationHelper.translate('eventNameLabel')}
                    placeholder={translationHelper.translate('notFiltered')}
                    fluid
                    multiple
                    search
                    selection
                    loading={eventNames.processing}
                    disabled={eventNames.processing}
                    options={eventOptions}
                    onChange={(_, { value }) => setEventList(value)}
                />
                {
                    apiState.error &&
                    <Message
                        negative
                        header={translationHelper.translate('errorHeader')}
                        content={apiState.error.message}
                    />
                }
                <Form.Button type='submit'>
                    {translationHelper.translate('submitExport')}
                </Form.Button>
            </Form>
        </>
    );
}
