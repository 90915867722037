import React from "react";

export const ContentRowStyle = () => {

    return (
        <>
            <style>
                {`
					#content-row{
						height: calc(100vh - ${document.getElementById('navigation-row')?.clientHeight ?? 0}px);
                        padding-top: 0;
                        padding-bottom: 0;
                        overflow-y: auto;
					}
				`}
            </style>
        </>
    )
};