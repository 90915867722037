import React from "react";

import { Loader, Header } from 'semantic-ui-react'

import { dateToString, groupBy } from '../../utils/helperFunktions.js'
import {  useFilteredData } from "../../utils/customHooks.js";

import { translationHelper } from '../../stores/translationDict.js';

import './LocationZoneView.css';

export const AssetsPerZone = () => {

    const [eventData] = useFilteredData('/api/view/asset_last_events', {}, { eventName: ["location"]});

    const groupedData = groupBy([...(eventData.values ?? [])], "assetId");

    const lastLocation = Object.keys(groupedData)
        .map(assetId =>
            groupedData[assetId].toSorted(function (a, b) {
                if (a.start < b.start) {
                    return 1;
                }
                if (a.start > b.start) {
                    return -1;
                }
                return 0;
            })[0]
        );
    
    const locationsGrouped = groupBy(lastLocation, "locationId");
    
    return (
        <>
            <div className="zonen_view">
                {
                    (
                        (
                            !Object.keys(locationsGrouped).length
                            || eventData.processing
                        ) &&
                        <Loader active>
                            <Header>
                                {translationHelper.translate('waitingForData')}
                            </Header>
                        </Loader>
                    )
                    ||
                    (
                        Object.keys(locationsGrouped)
                            .flatMap(locationKey => {
                                const zonesGrouped = groupBy(locationsGrouped[locationKey], "zoneId");
                                return Object.keys(zonesGrouped)
                                    .flatMap(zoneKey => {
                                        return (
                                            <div
                                                key={`${locationKey}-${zoneKey}`}
                                                className={"zonen_cart"}
                                            >
                                                <h1>{`${zonesGrouped[zoneKey][0].locationName} ${zonesGrouped[zoneKey][0].zoneName ? `- ${zonesGrouped[zoneKey][0].zoneName}` : ""}`}</h1>
                                                <div className="status_container">
                                                    {
                                                        zonesGrouped[zoneKey]
                                                            .map(x => (
                                                                <div
                                                                    key={x.assetId}
                                                                    className={"status_element"}
                                                                    style={{ color: "black" }}
                                                                >
                                                                    {x.categoryName} - {x.assetId} ({dateToString(x.start)}) ({x.confidence})
                                                                </div>
                                                            ))
                                                    }
                                                </div>
                                            </div>
                                        )
                                    });
                        })
                    )
                }
            </div>
        </>
    )
}