import React from "react";
import { useApiGet, useApiPost } from "../../utils/customHooks";
import { TableComp } from "../Layout/TableComp";
import { translationHelper } from '../../stores/translationDict.js';

export const CategoriesMasterData = () => {
    const classKeys = [
        "id",
        "name",
        "sku",
        "ownerID"
    ]
        .map(key => ({
            key,
            get name() { return translationHelper.translate(this.key + '_CategoryKey') }
        }));

    const [categories,, reloadCategories] = useApiGet('/api/wiliot/categories');
    const [categoriesPostState] = useApiPost('/api/wiliot/categories');

    return (
        <TableComp
            classKeys={classKeys}
            dataState={categories}
            reloadDataFunc={reloadCategories}
            actionState={categoriesPostState}
        />
    )
}
