import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import 'semantic-ui-css/semantic.min.css'

import { App } from './components/App.jsx';

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './ms_identity/authConfig.js';

import * as serviceWorkerRegistration from './worker/serviceWorkerRegistration.js';
import reportWebVitals from './worker/reportWebVitals.js';

(async () => {
    const msalInstance = new PublicClientApplication(msalConfig);

    await msalInstance.initialize();

    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    msalInstance.addEventCallback((event) => {
        if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS) &&
            event.payload.account
        ) {
            event?.payload?.account &&
            msalInstance.setActiveAccount(event.payload.account);
        }
    });

    return msalInstance
})()
    .then((msalInstance) => {
        const root = ReactDOM.createRoot(document.getElementById('root'));

        root.render(
            <React.StrictMode>
                <MsalProvider instance={msalInstance}>
                    <App />
                </MsalProvider>
            </React.StrictMode>
        );
    })


reportWebVitals();

serviceWorkerRegistration.register();