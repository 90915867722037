
const translationDict = {
    en: {
        editProfile: "Edit Profile",
        login: "Login",
        logout: "Logout",
        unknownUserName: "Unknown Username",
        online: "Online",
        offline: "Offline",
        errorHeader: "Error",
        reloadData: "Reload Data",
        rows: "Rows",
        page: "Page",
        liveViewToggle: "Live/Timefilter",
        searchPlaceholder: "Search ...",
        filterMinutes: "Period filter in minutes:",
        noDataHeader: "No data available.",
        waitingForData: "Waiting for Data",
        assetIdLabel: "AssetId:",
        eventNameLabel: "Eventname:",
        bridgeGatewayNameLabel: "Bridge/Gateway:",
        gatewayHeader: "Gateway",
        bridgeHeader: "Bridge",
        groupingCheckboxLabel: "hide unchanged intermediate data",
        TableView: "Live Table",
        DataExporter: "Data Export",
        TemperatureView: "Temperature Trend",
        LocationZoneView: "Location-Zone Trend",
        ConnectivityView: "Connectivity Trend",
        LocationMasterData: "Locations",
        ZoneMasterData: "Zones",
        CategoriesMasterData: "Categories",
        AssetsMasterData: "Assets",
        BridgesMasterData: "Bridges",
        GatewayMasterData: "Gateways",
        EventStatistic: "Asset-Event Statistic",
        AssetMonitor: "Asset Monitor",
        AssetPerZone: "Assets per Location",
        DatabaseTable: "Database View",
        id_LocationKey: "ID",
        name_LocationKey: "Name",
        type_LocationKey: "Type",
        ownerID_LocationKey: "Owner ID",
        id_ZoneKey: "ID",
        name_ZoneKey: "Name",
        locationID_ZoneKey: "Location ID",
        locationName_ZoneKey: "Location Name",
        ownerID_ZoneKey: "Owner ID",
        id_BridgeKey: "ID",
        name_BridgeKey: "Name",
        locationID_BridgeKey: "Location ID",
        locationName_BridgeKey: "Location Name",
        zoneID_BridgeKey: "Zone ID",
        zoneName_BridgeKey: "Zone Name",
        ownerID_BridgeKey: "Owner ID",
        id_CategoryKey: "ID",
        name_CategoryKey: "Name",
        sku_CategoryKey: "SKU",
        ownerID_CategoryKey: "Owner ID",
        id_AssetKey: "ID",
        name_AssetKey: "Name",
        createdAt_AssetKey: "Created At",
        lastUpdatedAt_AssetKey: "Last Update At",
        categoryId_AssetKey: "Category ID",
        categoryName_AssetKey: "Category Name",
        ownerID_AssetKey: "Owner ID",
        type_associations_AssetKey: "Type",
        value_associations_AssetKey: "Value",
        id_GatewayKey: "ID",
        name_GatewayKey: "Name",
        type_GatewayKey: "Type",
        ownerID_GatewayKey: "Owner ID",
        assetId_DatabaseTableKey: "Asset ID",
        categoryId_DatabaseTableKey: "Category ID",
        eventName_DatabaseTableKey: "Eventname",
        ownerId_DatabaseTableKey: "Owner ID",
        start_DatabaseTableKey: "Start",
        end_DatabaseTableKey: "End",
        createdOn_DatabaseTableKey: "Created On",
        value_DatabaseTableKey: "Value",
        confidence_DatabaseTableKey: "Confidence",
        temperature_DatabaseTableKey: "Temperature",
        locationId_DatabaseTableKey: "Location ID",
        zoneId_DatabaseTableKey: "Zone ID",
        locationName_DatabaseTableKey: "Location Name",
        zoneName_DatabaseTableKey: "Zone Name",
        deviceType_DatabaseTableKey: "Devicetype",
        active_DatabaseTableKey: "Active",
        connectivity_DatabaseTableKey: "Connectivity",
        latitude_DatabaseTableKey: "Latitude",
        longitude_DatabaseTableKey: "Longitude",
        distance_DatabaseTableKey: "Distance",
        staticUnauthenticatedHome: "Please login to access",
        staticNotAllowed: "You are not allowed to view this page",
        fromDatetime: "From:",
        toDatetime: "To:",
        submitExport: "Export",
        notFiltered: "not Filtered",
        LocationZoneLabel: "Location - Zone:",
        selectAccountLabel: "Select Account",
        ZoneFlowChecker: "Zone Flow Checker",
    }
}

let languageKey = "en";

const listeners = {
    list: [],
    emitChange() {
        for (let listener of this.list) {
            listener();
        }
    }
};

export const translationHelper = {
    setLanguage(key) {
        if (Object.keys(translationDict).includes(key)) {
            languageKey = key;
            listeners.emitChange();
        }
    },
    subscribe(listener) {
        listeners.list = [...listeners.list, listener];
        return () => {
            listeners.list = listeners.list.filter(l => l !== listener);
        };
    },
    getSnapshot() {
        return languageKey;
    },
    translate(key) {
        return translationDict[languageKey][key] ?? key
    },
};