import React from "react";
import { Segment, Header } from 'semantic-ui-react'
import { translationHelper } from '../../stores/translationDict.js';

export const NotAllowedTemplate = () => {
    return (
        <>
            <Segment placeholder>
                <Header textAlign='center'>
                    {translationHelper.translate('staticNotAllowed')}
                </Header>
            </Segment>
        </>
    )
}