import React from "react";

import { Loader, Header, Table, Grid, Icon } from 'semantic-ui-react'

import { dateToString, groupBy } from '../../utils/helperFunktions.js'

import { translationHelper } from '../../stores/translationDict.js';
import { useFilteredData } from "../../utils/customHooks.js";

const flowDiagram = {
    "848665524253": (prevZoneId, nextZoneId) => true,
    "376898445545": (prevZoneId, nextZoneId) => {
        if (prevZoneId === nextZoneId) {
            return true;
        }
        switch (prevZoneId) {
            case "e6333f67-3e07-4826-adef-1ad576fc5165": return nextZoneId === '65e2d148-15c6-48fe-a5dc-697fc546222b'
                || nextZoneId === '66aed08b-345f-4b25-80bb-231d1d854139'
            case "970e0eab-6aa1-4a46-b44d-7affb872f0d7": return nextZoneId === '65e2d148-15c6-48fe-a5dc-697fc546222b'
                || nextZoneId === '66aed08b-345f-4b25-80bb-231d1d854139'
            case "65e2d148-15c6-48fe-a5dc-697fc546222b": return nextZoneId === 'e6333f67-3e07-4826-adef-1ad576fc5165'
                || nextZoneId === '970e0eab-6aa1-4a46-b44d-7affb872f0d7'
            case "cd4604ec-d2f7-488d-937b-5aff96d71e5e": return nextZoneId === '1301b6c3-d34c-45fb-8813-690e3b9740cc'
                || nextZoneId === '43b31582-335e-4e5a-b6ca-a759e5ea1605'
            case "66aed08b-345f-4b25-80bb-231d1d854139": return nextZoneId === 'cd4604ec-d2f7-488d-937b-5aff96d71e5e'
                || nextZoneId === '43b31582-335e-4e5a-b6ca-a759e5ea1605'
            case "43b31582-335e-4e5a-b6ca-a759e5ea1605": return nextZoneId === 'cd4604ec-d2f7-488d-937b-5aff96d71e5e'
                || nextZoneId === '1301b6c3-d34c-45fb-8813-690e3b9740cc'
            case "1301b6c3-d34c-45fb-8813-690e3b9740cc": return nextZoneId === 'e6333f67-3e07-4826-adef-1ad576fc5165'
                || nextZoneId === '970e0eab-6aa1-4a46-b44d-7affb872f0d7'
            default: return false
        }
    }
}

export const ZoneFlowChecker = () => {

    const [assetLocations] = useFilteredData('/api/view/location', {}, {eventName: ["location"]});

    const groupedAssets = groupBy(
        [...(assetLocations.values ?? [])]
            .filter(x => Object.keys(flowDiagram).includes(x.ownerId)),
        "assetId"
    );
    
    Object
        .keys(groupedAssets)
        .forEach(assetId => {
            groupedAssets[assetId] = groupedAssets[assetId]
                .map(x => ({
                    locationId: x.locationId,
                    locationName: x.locationName,
                    zoneId: x.zoneId,
                    zoneName: x.zoneName,
                    start: x.start,
                    ownerId: x.ownerId
                }))
                .toSorted((a, b) => 
                    (a.start?.valueOf() ?? 0) - (b.start?.valueOf() ?? 0)
                );
        });

    const toEventText = (event) => event ?
        (
            <Header as='h4'>
                {event.locationName} - {event.zoneName}
                <Header.Subheader>
                    {dateToString(event.start)}
                </Header.Subheader>
            </Header>
        )
        :
        '';

    const isBadFlow = (prevEvent, nextEvent) => {
        if (!prevEvent || !nextEvent) {
            return false;
        }
        if (flowDiagram[nextEvent.ownerId] === undefined) {
            return false;
        }
        return !flowDiagram[nextEvent.ownerId](prevEvent.zoneId, nextEvent.zoneId);
    }

    return (
        <>
            {
                (
                    assetLocations.processing &&
                    <Loader active>
                        <Header>
                            {translationHelper.translate('waitingForData')}
                        </Header>
                    </Loader>
                )
                ||
                (
                    !Object.keys(groupedAssets).length &&
                    <Header>
                        {translationHelper.translate('noDataHeader')}
                    </Header>
                )
                ||
                (
                    <Grid celled>
                        {
                            (() => {
                                const array = Object.keys(groupedAssets);
                                const num = document.documentElement.clientWidth > 1170 ? 2 : 1;
                                const group = [];

                                for (let i = 0; i < array.length; i += num) {
                                    group.push(array.slice(i, i + num));
                                }

                                return group;
                            })()
                                .map((groupedAssetes, rowIndex) => (
                                    <Grid.Row key={"ZoneFlowRow" + rowIndex} columns={groupedAssetes.length}>
                                        {
                                            groupedAssetes.map(assetId => (
                                                <Grid.Column key={assetId}>
                                                    <Header textAlign='center'>{assetId}</Header>
                                                    <Table celled singleLine fixed>
                                                        <Table.Body>
                                                            {
                                                                groupedAssets[assetId]
                                                                    .filter((event, eventIndex, eventArray) =>
                                                                        eventArray[eventIndex - 1]?.zoneId !== event.zoneId
                                                                    )
                                                                    .map((event, eventIndex, eventArray) => (
                                                                        <Table.Row
                                                                            key={"ZoneFlowRow" + rowIndex + "tableRow" + eventIndex}
                                                                            negative={isBadFlow(eventArray[eventIndex - 1], event)}
                                                                        >
                                                                            <Table.Cell>
                                                                                {toEventText(eventArray[eventIndex - 1])}
                                                                            </Table.Cell>
                                                                            <Table.Cell textAlign='center' width='2'>
                                                                                <Icon name='arrow right' />
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {toEventText(event)}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    ))
                                                                    .toReversed()
                                                            }
                                                        </Table.Body>
                                                    </Table>
                                                </Grid.Column>
                                            ))
                                        }
                                    </Grid.Row>
                                ))
                        }
                    </Grid>
                )
            }
        </>
    )
}