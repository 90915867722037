import React from "react";
import { useApiGet, useApiPost } from "../../utils/customHooks";
import { TableComp } from "../Layout/TableComp";
import { translationHelper } from '../../stores/translationDict.js';

export const ZoneMasterData = () => {
    const classKeys = [
        "locationID",
        "locationName",
        "id",
        "name",
        "ownerID"
    ]
        .map(key => ({
            key,
            get name() { return translationHelper.translate(this.key + '_ZoneKey') }
        }));

    const [zones, , reloadZones] = useApiGet('/api/wiliot/zones');
    const [zonePostState] = useApiPost('/api/wiliot/zones');

    return (
        <TableComp
            classKeys={classKeys}
            dataState={zones}
            reloadDataFunc={reloadZones}
            actionState={zonePostState}
        />
    )
}