import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { translationHelper } from '../../stores/translationDict.js';

export const UnauthenticatedHomeTemplate = () => {
    return (
        <>
            <Segment placeholder>
                <Header textAlign='center'>
                    {translationHelper.translate('staticUnauthenticatedHome')}
                </Header>
            </Segment>
        </>
    );
}