import React, { useState } from "react";

import { Form, Loader, Header } from 'semantic-ui-react'

import { dateToString, groupBy } from '../../utils/helperFunktions.js'

import { translationHelper } from '../../stores/translationDict.js';

import './LocationZoneView.css'

import ReactEcharts from "echarts-for-react";
import { useFilteredData } from "../../utils/customHooks.js";
import { filterHelper, filterKeyName, filterKeys } from "../../stores/filterManagement.js";

export const LocationZoneView = () => {

    const [assetLocations] = useFilteredData('/api/view/location', {}, { eventName: ["location"] });

    const [isGroupingActive, setGroupingActive] = useState(true);

    const filterFrom = filterHelper.getFilter(filterKeyName(filterKeys.fromDate));
    const filterTo = filterHelper.getFilter(filterKeyName(filterKeys.toDate));

    const startDate = filterFrom ? new Date(filterFrom) : new Date(new Date().valueOf() - (1000 * 60 * 60));
    const endDate = filterTo ? new Date(filterTo) : new Date(new Date().valueOf() + (1000 * 60 * 5));

    const data = [...(assetLocations.values ?? [])]
        .toSorted((a, b) => (a['assetId'] ?? '').localeCompare(b['assetId'] ?? ''));

    const groupedData = groupBy(data, "assetId");

    let colors = {};
    const hashCode = s => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
    const locationsGrouped = groupBy(data, "locationId");
    Object.keys(locationsGrouped)
        .forEach(locationKey => {
            const zonesGrouped = groupBy(locationsGrouped[locationKey], "zoneId");
            Object.keys(zonesGrouped)
                .forEach(zoneKey => {
                    colors[locationKey] = {
                        ...colors[locationKey],
                        [zoneKey]: "#" + Math.abs(hashCode(zoneKey + locationKey)).toString(16).slice(0,6)
                    };
                });
        });
    
    Object.keys(groupedData)
        .forEach(key => {
            groupedData[key] = groupedData[key]
                .toSorted((a, b) =>
                    (a.start?.valueOf() ?? 0) - (b.start?.valueOf() ?? 0)
                )
                .filter((event, eventIndex, eventArray) => !isGroupingActive || !(
                    eventArray[eventIndex - 1]?.locationId === event.locationId
                    //&& eventArray[eventIndex + 1]?.locationId === event.locationId
                    && eventArray[eventIndex - 1]?.zoneId === event.zoneId
                    //&& eventArray[eventIndex + 1]?.zoneId === event.zoneId
                ));
        });

    const options = {
        xAxis: {
            type: "time",
            splitLine: {
                show: false
            },
            min: startDate,
            max: endDate,
            interval: (1000 * 60 * 5),
        },
        yAxis: {
            type: "value",
            show: false,
        },
        grid: {
            top: 15,
            left: 15,
            right: 15,
            bottom: 15,
            height: 160
        }
    }

    return (
        <>
            <Form>
                <Form.Group inline>
                    <Form.Checkbox
                        checked={isGroupingActive}
                        label={translationHelper.translate('groupingCheckboxLabel')}
                        onChange={() => setGroupingActive(!isGroupingActive)}
                    />
                </Form.Group>
            </Form>
            <div className="zonen_view">
                {
                    (
                        assetLocations.processing &&
                        <Loader active>
                            <Header>
                                {translationHelper.translate('waitingForData')}
                            </Header>
                        </Loader>
                    )
                    ||
                    (
                        !Object.keys(groupedData).length &&
                        <Header>
                            {translationHelper.translate('noDataHeader')}
                        </Header>
                    )
                    ||
                    (
                        Object.keys(groupedData)
                            .map(key => (
                                <div key={key} className={"zonen_cart"}>
                                    <h1>{groupedData[key][0]?.categoryName}</h1>
                                    <h1>{key}</h1>
                                    <ReactEcharts
                                        notMerge={true}
                                        option={{
                                            ...options,
                                            series: [...groupedData[key]]
                                                .map((ele, index) => ({
                                                    name: ele.locationName + (ele.zoneName ? ` - ${ele.zoneName}` : ''),
                                                    type: "line",
                                                    symbol: "none",
                                                    symbolSize: 5,
                                                    sampling: "average",
                                                    areaStyle: {},
                                                    itemStyle: {
                                                        color: colors[ele.locationId][ele.zoneId]
                                                    },
                                                    data: [
                                                        [ele.start, 1],
                                                        [(groupedData[key][index + 1]?.start ?? new Date()), 1],
                                                    ]
                                                }))
                                        }}
                                    />
                                    <div className="status_container">
                                        {
                                            [...groupedData[key]]
                                                .reverse()
                                                .map((ele, i) => (
                                                    <div
                                                        key={key + "_i_" + i}
                                                        className={"status_element"}
                                                        style={{ backgroundColor: colors[ele.locationId][ele.zoneId] }}
                                                    >
                                                        {ele.locationName + (ele.zoneName ? ` - ${ele.zoneName}` : '')} ({dateToString(ele.start)}) ({ele.confidence})
                                                    </div>
                                                ))
                                        }
                                    </div>
                                </div>
                            ))
                    )
                }
            </div>
        </>
    )
}