import React from "react";
import { useApiGet, useApiPost } from "../../utils/customHooks";
import { TableComp } from "../Layout/TableComp";
import { translationHelper } from '../../stores/translationDict.js';

export const AssetsMasterData = () => {
    const classKeys = [
        "id",
        "name",
        "createdAt",
        "lastUpdatedAt",
        "categoryId",
        "categoryName",
        "ownerID"
    ]
        .map(key => ({
            key,
            get name() { return translationHelper.translate(this.key + '_AssetKey') }
        }));

    const innerTable = {
        key: "associations",
        innerKeys: [
            "type",
            "value"
        ]
            .map(key => ({
                key,
                get name() { return translationHelper.translate(this.key + '_associations_AssetKey') }
            }))
    };

    const [categories,, reloadCategories] = useApiGet('/api/wiliot/assets');
    const [categoriesPostState] = useApiPost('/api/wiliot/assets');

    categories.values?.forEach(x => {
        x["createdAt"] = x["createdAt"] ? new Date(x["createdAt"]) : undefined;
        x["lastUpdatedAt"] = x["lastUpdatedAt"] ? new Date(x["lastUpdatedAt"]) : undefined;
    });

    return (
        <TableComp
            classKeys={classKeys}
            innerTable={innerTable}
            dataState={categories}
            reloadDataFunc={reloadCategories}
            actionState={categoriesPostState}
        />
    )
}
