
export const WiliotRights = {
    isValidRight(wiliotRight) {
        return [this.reader, this.writer, this.admin].includes(wiliotRight);
    },
    isAdmin(wiliotRight) {
        return wiliotRight === this.admin;
    },
    isWriter(wiliotRight) {
        return this.isAdmin(wiliotRight) || wiliotRight === this.writer;
    },
    isReader(wiliotRight) {
        return this.isWriter(wiliotRight) || wiliotRight === this.reader;
    },
    get reader() { return "lesen" },
    get writer() { return "lesen_schreiben" },
    get admin() { return "admin" },
}

export const WiliotAccounts = {
    containsAccount(accountList, destAccounts) {
        return accountList.some(x => destAccounts.includes(x)) || accountList[0] === 'alle'
    }
}