const data = {
    keys: [],
    values: []
};

const listeners = {
    list: [],
    emitChange() {
        for (let listener of this.list) {
            listener();
        }
    }
};

export const wiliotDataHelper = {
    async addObj(obj) {
        obj["start"] = obj["start"] ? new Date(obj["start"]) : undefined;
        obj["end"] = obj["end"] ? new Date(obj["end"]) : undefined;
        obj["createdOn"] = obj["createdOn"] ? new Date(obj["createdOn"]) : undefined;
        
        data.keys = [...new Set([...data.keys, ...Object.keys(obj)])]
        data.values.push(obj);

        removeOverflow();

        listeners.emitChange();
    },
    async addInitialList(compressedList) {
        let list = transformCompressedList(compressedList)

        list.forEach(obj => {
            obj["start"] = obj["start"] ? new Date(obj["start"]) : undefined;
            obj["end"] = obj["end"] ? new Date(obj["end"]) : undefined;
            obj["createdOn"] = obj["createdOn"] ? new Date(obj["createdOn"]) : undefined;
        })
        data.keys = [...new Set([...data.keys, ...list.flatMap(obj => Object.keys(obj))])]

        const chunkSize = 30000;
        for (let i = 0; i < list.length; i += chunkSize) {
            const chunk = list.slice(i, i + chunkSize);
            data.values.push(...chunk);
        }

        removeOverflow();

        listeners.emitChange();
    },
    clear() {
        data.keys = [];
        data.values = [];
        listeners.emitChange();
    },
    subscribe(listener) {
        listeners.list = [...listeners.list, listener];
        return () => {
            listeners.list = listeners.list.filter(l => l !== listener);
        };
    },
    getSnapshotValues() {
        return data.values;
    },
    getSnapshotKeys() {
        return data.keys;
    },
};

const transformCompressedList = (compressedList) =>
    compressedList
        .flatMap(x => {
            const defaultObj = (y)=>({
                ownerId: x.ownerId,
                assetId: x.assetId,
                bridgeName: x.bridgeName,
                gatewayName: x.gatewayName,
                categoryId: x.categoryId,
                categoryName: x.categoryName,
                eventName: undefined,
                value: undefined,
                confidence: y.confidence,
                start: y.timestamp,
                end: y.timestamp,
                createdOn: y.timestamp,
                temperature: undefined,
                locationId: undefined,
                locationName: undefined,
                zoneId: undefined,
                zoneName: undefined,
                deviceType: undefined,
                active: undefined,
                connectivity: undefined,
                latitude: undefined,
                longitude: undefined,
                distance: undefined,
            });
            return [
                ...x.temperature.map(y => ({
                    ...defaultObj(y),
                    eventName: 'temperature',
                    value: y.value?.toString(),
                    temperature: y.value
                })),
                ...x.location.map(y => ({
                    ...defaultObj(y),
                    eventName: 'location',
                    value: y.locationId + ',' + y.zoneId,
                    locationId: y.locationId,
                    locationName: y.locationName,
                    zoneId: y.zoneId,
                    zoneName: y.zoneName,
                    deviceType: y.deviceType,
                })),
                ...x.active.map(y => ({
                    ...defaultObj(y),
                    eventName: 'active',
                    value: y.value ? "1" : "0",
                    active: y.value
                })),
                ...x.connectivity.map(y => ({
                    ...defaultObj(y),
                    eventName: 'connectivity',
                    value: y.value ? "1" : "0",
                    connectivity: y.value

                })),
                ...x.geolocation.map(y => ({
                    ...defaultObj(y),
                    eventName: 'geolocation',
                    value: y.latitude + ',' + y.longitude,
                    latitude: y.latitude,
                    longitude: y.longitude,
                    distance: y.distance,

                })),
            ]
        });

const removeOverflow = () => {
    data.values = [...data.values.filter(x => x.createdOn >= new Date(new Date().valueOf() - (1000 * 60 * 60 * 6)))]
}