
export const httpGet = (accessToken, apiEndpoint, searchQuery = {}, signal) => {

    const options = {
        signal,
        method: "GET",
        cache: "no-cache",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': "application/json"
        },
    };

    return fetch(
        apiEndpoint + getQueryString(searchQuery),
        options
    )
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error(`${response.status} - ${response.statusText}`)
            }
        })
};

export const httpPost = (accessToken, apiEndpoint, bodyData = {}, searchQuery = {}) => {

    const options = {
        method: "POST",
        cache: "no-cache",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': "application/json"
        },
        body: JSON.stringify(bodyData)
    };

    return fetch(
        apiEndpoint + getQueryString(searchQuery),
        options
    )
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error(`${response.status} - ${response.statusText}`)
            }
        })
};

export const httpGetBlob = (accessToken, apiEndpoint, searchQuery = {}) => {

    const options = {
        method: "POST",
        cache: "no-cache",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': "application/json"
        },
        responseType: 'blob'
    };

    return fetch(
        apiEndpoint + getQueryString(searchQuery),
        options
    )
        .then(async (response) => {
            if (response.ok) {
                return {
                    data: await response.blob(),
                    name: response.headers.get('content-disposition').split('filename=')[1].split(';')[0].replace('"', "")
                };
            }
            else {
                throw new Error(`${response.status} - ${response.statusText}`)
            }
        })
};

export const getQueryString = (searchQuery) => {

    let params = new URLSearchParams();

    Object
        .keys(searchQuery)
        .forEach(key => {
            if (Array.isArray(searchQuery[key])) {
                searchQuery[key]
                    .forEach(ele => {
                        params.append(key, ele)
                    })
            }
            else {
                if (searchQuery[key]) {
                    params.append(key, searchQuery[key])
                }
            }
        });

    return params.size ? `?${params.toString()}` : "";
}