
export const filterKeys = {
    useLiveData: {
        type: 'bool',
        default: true
    },
    useApiData: {
        type: 'bool',
        default: false
    },
    fromDate: {
        type: 'datetime',
        default: new Date((new Date()).valueOf() - (1000 * 60 * 60 * 6)).toJSON()
    },
    toDate: {
        type: 'datetime',
        default: null
    },
    ownerId: {
        type: 'array',
        default: []
    },
    assetId: {
        type: 'array',
        default: []
    },
    categoryId: {
        type: 'array',
        default: []
    },
    categoryName: {
        type: 'array',
        default: []
    },
    eventName: {
        type: 'array',
        default: []
    },
    minConfidence: {
        type: 'number',
        default: 0.0
    },
    minTemperatur: {
        type: 'number',
        default: null
    },
    maxTemperatur: {
        type: 'number',
        default: null
    },
    locationId: {
        type: 'array',
        default: []
    },
    locationName: {
        type: 'array',
        default: []
    },
    zoneId: {
        type: 'array',
        default: []
    },
    zoneName: {
        type: 'array',
        default: []
    },
    active: {
        type: 'bool',
        default: null
    },
    connectivity: {
        type: 'bool',
        default: null
    },
};

export const filterKeyName = (obj) => {
    return Object.keys(filterKeys).find(key => filterKeys[key] === obj)
}

const storageKey = 'WWA_PWA_FilterStorage'

let currentFilterSettings = {};

try {
    currentFilterSettings = JSON.parse(sessionStorage.getItem(storageKey)) ?? {};
}
catch (error) {
    console.log(error);
    currentFilterSettings = {};
}

const listeners = {
    list: [],
    emitChange() {
        for (let listener of this.list) {
            listener();
        }
    }
};

export const filterHelper = {
    setFilter(key, value) {
        currentFilterSettings[key] = value;
        sessionStorage.setItem(storageKey, JSON.stringify(currentFilterSettings));
        listeners.emitChange();
    },
    subscribe(listener) {
        listeners.list = [...listeners.list, listener];
        return () => {
            listeners.list = listeners.list.filter(l => l !== listener);
        };
    },
    getSnapshot() {
        return JSON.stringify(currentFilterSettings);
    },
    getFilter(key) {
        return Object.keys(currentFilterSettings).includes(key) ?
            currentFilterSettings[key] ?? null
            :
            filterKeys[key]?.default;
    },
    deleteAllFilter() {
        currentFilterSettings = {};
        sessionStorage.removeItem(storageKey);
        listeners.emitChange();
    }
};