import React, { useState } from "react";
import { useFilteredData } from "../../utils/customHooks";
import { TableComp } from "../Layout/TableComp";
import { translationHelper } from '../../stores/translationDict.js';
import { Message, Form } from 'semantic-ui-react';

export const DatabaseTable = () => {
    const classKeys = [
        "assetId"
        ,"categoryId"
        ,"eventName"
        ,"ownerId"
        ,"start"
        ,"end"
        ,"createdOn"
        ,"value"
        ,"confidence"
        ,"temperature"
        ,"locationId"
        ,"locationName"
        ,"zoneId"
        ,"zoneName"
        ,"deviceType"
        ,"active"
        ,"connectivity"
        ,"latitude"
        ,"longitude"
        ,"distance"
    ]
        .map(key => ({
            key,
            get name() { return translationHelper.translate(this.key + '_DatabaseTableKey') }
        }));

    const [eventData, reloadEventData] = useFilteredData('/api/view/database', {}, { useLiveData: false, useApiData: true});
    const [showMessage, setShowMessage] = useState(true);

    return (
        <>
            <Form>
                {
                    showMessage &&
                    <Message onDismiss={() => { setShowMessage(false) }}>
                        <Message.Header>Large Data Warning</Message.Header>
                        <Message.Content>
                            This table will display all data stored in the database, without filtering.
                            <br />
                            If you realy want to load the data, click "Reload Data".
                            <br />
                            You may want do use the filter to reduce the data size.
                            <br />
                            <br />
                            Warning: This will take some time to display!
                        </Message.Content>
                    </Message>
                }
            </Form>
            <TableComp
                classKeys={classKeys}
                dataState={eventData}
                reloadDataFunc={reloadEventData}
            />
        </>
    )
}
