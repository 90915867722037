import { HubConnectionBuilder, HttpTransportType, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { wiliotDataHelper } from '../stores/wiliotLiveData.js'
import { getAccessToken } from '../ms_identity/authConfig.js';

let _connection = null;

const initHubConnection = (msalInstance) => {
    _connection = new HubConnectionBuilder()
        .withUrl(
            `/api/live/wiliot`,
            {
                transport: HttpTransportType.WebSockets,
                skipNegotiation: true,
                accessTokenFactory: () => getAccessToken(msalInstance)
            }
        )
        .configureLogging(LogLevel.Debug)
        .build();

    _connection
        .on('init_data', async (message) => {
            wiliotDataHelper.addInitialList(message);
        });

    _connection
        .on('new_data', async (message) => {
            wiliotDataHelper.addObj(message);
        });

    _connection
        .onclose(async (err) => {
            if (err) {
                console.log(err);
                await new Promise(res => setTimeout(res, 2000));
                startWebSocketConnection(msalInstance);
            }
        });
}

export const startWebSocketConnection = (msalInstance) => {

    if (!_connection) {
        initHubConnection(msalInstance);
    }

    if (_connection.state === HubConnectionState.Disconnected) {
        _connection
            .start()
            .catch(err => {
                console.log(err);
            });
    }
};

export const stopWebSocketConnection = () => {
    if (_connection?.state === HubConnectionState.Connected) {
        _connection
            ?.onclose((err) => {
                console.log("stopping connection", err)
            });

        _connection
            ?.off('new_data');

        _connection
            ?.off('init_data');

        _connection
            ?.stop()
            ?.catch(err => {
                console.log(err);
            });

        wiliotDataHelper.clear();

        _connection = null;
    }
};