import React, { useEffect, useSyncExternalStore } from 'react';
import { EventType } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

import { Grid } from 'semantic-ui-react'

import { NavigationRow } from './Layout/NavigationRow.jsx';
import { ContentBody } from './Layout/ContentBody.jsx';

import { translationHelper } from '../stores/translationDict.js';

import {startWebSocketConnection, stopWebSocketConnection} from '../service/hubs';
import { b2cPolicies, compareIssuingPolicy, loginRequest } from '../ms_identity/authConfig.js';
import { UnauthenticatedHomeTemplate } from './StaticTemplates/Unauthenticated.jsx';

export const App = () => {
    const languageKey = useSyncExternalStore(translationHelper.subscribe, translationHelper.getSnapshot);
    
    const { instance } = useMsal();

    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                                compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)
                        );

                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    };

                    instance.ssoSilent(signUpSignInFlowRequest).catch(e => console.log(e));
                }

                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: [...loginRequest.scopes],
                    };
                    instance.loginPopup(signUpSignInFlowRequest);
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [...loginRequest.scopes],
                    };
                    instance.loginPopup(resetPasswordRequest);
                }
            }

            if (instance.getAllAccounts()?.[0]) {
                startWebSocketConnection(instance);
            }
        });

        return () => {
            if (callbackId) {
                stopWebSocketConnection();

                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    return (
        <>
            <meta charSet={languageKey} />

            <Grid columns='equal' padded>

                <NavigationRow />

                <Grid.Row id='content-row'>
                    <Grid.Column width='16' style={{overflowX: 'auto'}}>
                        <AuthenticatedTemplate>
                            <ContentBody />
                        </AuthenticatedTemplate>

                        <UnauthenticatedTemplate>
                            <UnauthenticatedHomeTemplate />
                        </UnauthenticatedTemplate>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}