import { useEffect, useState, useSyncExternalStore } from "react";
import { useMsal } from "@azure/msal-react";

import { getAccessToken } from "../ms_identity/authConfig.js";
import { httpGet, httpGetBlob, httpPost } from "../service/contollers.js";
import { wiliotDataHelper } from "../stores/wiliotLiveData.js";
import { filterHelper, filterKeyName, filterKeys } from "../stores/filterManagement.js";

export const useApiGet = (apiEndpoint, defaultState = {}, autoRequest = true) => {

    const { instance } = useMsal();
    
    const [data, setData] = useState({
        ...defaultState,
        processing: autoRequest,
        parameter: {},
        requestIndex: 1
    });

    const fetchData = async (forceReload = false) => {

        const cancelController = new AbortController();

        setData({ processing: true, doCancel: function(){ cancelController.abort() }, parameter: { ...(data.parameter ?? {}) } });

        try {

            const token = await getAccessToken(instance);

            const res = await httpGet(
                token,
                apiEndpoint,
                {
                    forceReload,
                    ...(data.parameter ?? {})
                },
                cancelController.signal
            );

            setData({
                values: res,
                processing: false,
                parameter: { ...(data.parameter ?? {}) },
                doCancel: data.doCancel
            });
        }
        catch (error) {
            console.log(error);
            setData({
                ...(cancelController.signal.aborted ? {} : { error }),
                ...(cancelController.signal.aborted ? { processing: data.processing } : { processing: false }),
                parameter: { ...(data.parameter ?? {}) },
                doCancel: data.doCancel
            });
        }
    };

    useEffect(() => {

        if (autoRequest) {
            fetchData();
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [
        data,
        (dataInput) => {
            setData({
                processing: false,
                values: dataInput,
                parameter: { ...(data.parameter ?? {}) },
                doCancel: data.doCancel
            })
        },
        () => {
            data?.doCancel?.();
            fetchData(true);
        }]
}

export const useApiPost = (apiEndpoint) => {

    const { instance } = useMsal();
    const [data, setData] = useState({ processing: false, parameter: {} });

    const fetchData = async (postData) => {

        setData({ processing: true, apiEndpoint, parameter: { ...(data.parameter ?? {}) } });

        try {
            const token = await getAccessToken(instance);

            await httpPost(
                token,
                apiEndpoint,
                postData,
                data.parameter
            )

            setData({ processing: false, parameter: { ...(data.parameter ?? {}) } });
        }
        catch (error) {
            console.log(error);
            setData({ processing: false, error, parameter: { ...(data.parameter ?? {}) } });
        }
    };

    return [data, fetchData]
}

export const useApiFileExport = (apiEndpoint) => {

    const { instance } = useMsal();
    const [data, setData] = useState({ processing: false });

    const fetchData = async () => {

        setData({ processing: true, apiEndpoint: apiEndpoint });

        try {
            const token = await getAccessToken(instance);

            const responseBlob = await httpGetBlob(
                token,
                apiEndpoint,
                data.parameter
            )
            
            const filename = decodeURI(responseBlob.name);
            const url = window.URL.createObjectURL(responseBlob.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();

            setData({ processing: false });
        }
        catch (error) {
            console.log(error);

            setData({ processing: false, error });
        }
    };

    return [data, fetchData]
}

export const useMsalWiliotIDs = () => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount()
    return account?.['idTokenClaims']?.['extension_Wiliot_ID']?.split(',');
}

export const useMsalWiliotRight = () => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount()
    return account?.['idTokenClaims']?.['extension_Wiliot_Recht'];
}

export const useFilteredData = (apiEndpoint, defaultState = {}, apiParameter = { }) => {
    const filterString = useSyncExternalStore(filterHelper.subscribe, filterHelper.getSnapshot);
    const wiliotDataValues = useSyncExternalStore(wiliotDataHelper.subscribe, wiliotDataHelper.getSnapshotValues);
    const [apiData, , reloadApiData] = useApiGet(
        apiEndpoint,
        defaultState,
        false
    );

    const apiParameterKeys = Object.keys(apiParameter);

    const useApiData = apiParameterKeys.includes(filterKeyName(filterKeys.useApiData)) ?
        apiParameter.useApiData
        :
        filterHelper.getFilter(filterKeyName(filterKeys.useApiData));
    const useLiveData = apiParameterKeys.includes(filterKeyName(filterKeys.useLiveData)) ?
        apiParameter.useLiveData
        :
        filterHelper.getFilter(filterKeyName(filterKeys.useLiveData));

    apiData.parameter = {
        from: filterHelper.getFilter(filterKeyName(filterKeys.fromDate)),
        to: filterHelper.getFilter(filterKeyName(filterKeys.toDate)),
        assetId: filterHelper.getFilter(filterKeyName(filterKeys.assetId)),
        categoryId: filterHelper.getFilter(filterKeyName(filterKeys.categoryId)),
        locationId: filterHelper.getFilter(filterKeyName(filterKeys.locationId)),
        zoneId: filterHelper.getFilter(filterKeyName(filterKeys.zoneId)),
        eventName: filterHelper.getFilter(filterKeyName(filterKeys.eventName)),
        minConfidence: filterHelper.getFilter(filterKeyName(filterKeys.minConfidence)),
        ...(apiParameter ?? {})
    }

    useEffect(() => {
        if (useApiData) {
            reloadApiData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterString]);

    return [
        {
            ...(useApiData ? apiData : {}),
            values: [
                ...(useApiData ? (apiData.values ?? []) : [])
                    .map(x => {
                        x["start"] = x["start"] ? new Date(x["start"]) : undefined;
                        x["end"] = x["end"] ? new Date(x["end"]) : undefined;
                        x["createdOn"] = x["createdOn"] ? new Date(x["createdOn"]) : undefined;
                        return x;
                    }),
                ...(useLiveData ? wiliotDataValues : [])
            ]
                .filter(x => 
                    (
                        apiParameterKeys.includes(filterKeyName(filterKeys.ownerId)) ?
                            apiParameter[filterKeyName(filterKeys.ownerId)].includes(x.ownerId)
                            :
                            filterHelper.getFilter(filterKeyName(filterKeys.ownerId)).length === 0
                            || filterHelper.getFilter(filterKeyName(filterKeys.ownerId)).includes(x.ownerId)
                    )
                    && (
                        apiParameterKeys.includes(filterKeyName(filterKeys.assetId)) ?
                            apiParameter[filterKeyName(filterKeys.assetId)].includes(x.assetId)
                            :
                            filterHelper.getFilter(filterKeyName(filterKeys.assetId)).length === 0
                            || filterHelper.getFilter(filterKeyName(filterKeys.assetId)).includes(x.assetId)
                    )
                    && (
                        apiParameterKeys.includes(filterKeyName(filterKeys.categoryId)) ?
                            apiParameter[filterKeyName(filterKeys.categoryId)].includes(x.categoryId)
                            :
                            filterHelper.getFilter(filterKeyName(filterKeys.categoryId)).length === 0
                            || filterHelper.getFilter(filterKeyName(filterKeys.categoryId)).includes(x.categoryId)
                    )
                    && (
                        apiParameterKeys.includes(filterKeyName(filterKeys.categoryName)) ?
                            apiParameter[filterKeyName(filterKeys.categoryName)].includes(x.categoryName)
                            :
                            filterHelper.getFilter(filterKeyName(filterKeys.categoryName)).length === 0
                            || filterHelper.getFilter(filterKeyName(filterKeys.categoryName)).includes(x.categoryName)
                    )
                    && (
                        apiParameterKeys.includes(filterKeyName(filterKeys.eventName)) ?
                            apiParameter[filterKeyName(filterKeys.eventName)].includes(x.eventName)
                            :
                            filterHelper.getFilter(filterKeyName(filterKeys.eventName)).length === 0
                            || filterHelper.getFilter(filterKeyName(filterKeys.eventName)).includes(x.eventName)
                    )
                    && (
                        apiParameterKeys.includes(filterKeyName(filterKeys.locationId)) ?
                            apiParameter[filterKeyName(filterKeys.locationId)].includes(x.locationId)
                            :
                            filterHelper.getFilter(filterKeyName(filterKeys.locationId)).length === 0
                            || filterHelper.getFilter(filterKeyName(filterKeys.locationId)).includes(x.locationId)
                    )
                    && (
                        apiParameterKeys.includes(filterKeyName(filterKeys.locationName)) ?
                            apiParameter[filterKeyName(filterKeys.locationName)].includes(x.locationName)
                            :
                            filterHelper.getFilter(filterKeyName(filterKeys.locationName)).length === 0
                            || filterHelper.getFilter(filterKeyName(filterKeys.locationName)).includes(x.locationName)
                    )
                    && (
                        apiParameterKeys.includes(filterKeyName(filterKeys.zoneId)) ?
                            apiParameter[filterKeyName(filterKeys.zoneId)].includes(x.zoneId)
                            :
                            filterHelper.getFilter(filterKeyName(filterKeys.zoneId)).length === 0
                            || filterHelper.getFilter(filterKeyName(filterKeys.zoneId)).includes(x.zoneId)
                    )
                    && (
                        apiParameterKeys.includes(filterKeyName(filterKeys.zoneName)) ?
                            apiParameter[filterKeyName(filterKeys.zoneName)].includes(x.zoneName)
                            :
                            filterHelper.getFilter(filterKeyName(filterKeys.zoneName)).length === 0
                            || filterHelper.getFilter(filterKeyName(filterKeys.zoneName)).includes(x.zoneName)
                    )
                    && (
                        (filterHelper.getFilter(filterKeyName(filterKeys.fromDate)) === null)
                        || (x.createdOn.toJSON() >= filterHelper.getFilter(filterKeyName(filterKeys.fromDate)))
                    )
                    && (
                        (filterHelper.getFilter(filterKeyName(filterKeys.toDate)) === null)
                        || (x.createdOn.toJSON() < filterHelper.getFilter(filterKeyName(filterKeys.toDate)))
                    )
                    && (
                        (filterHelper.getFilter(filterKeyName(filterKeys.minConfidence)) === null)
                        || (x.confidence >= filterHelper.getFilter(filterKeyName(filterKeys.minConfidence)))
                    )
                    && (
                        (filterHelper.getFilter(filterKeyName(filterKeys.minTemperatur)) === null)
                        || (x.temperature === null)
                        || (x.temperature >= filterHelper.getFilter(filterKeyName(filterKeys.minTemperatur)))
                    )
                    && (
                        (filterHelper.getFilter(filterKeyName(filterKeys.maxTemperatur)) === null)
                        || (x.temperature === null)
                        || (x.temperature < filterHelper.getFilter(filterKeyName(filterKeys.maxTemperatur)))
                    )
                    && (
                        (filterHelper.getFilter(filterKeyName(filterKeys.active)) === null)
                        || (x.active === null)
                        || (x.active === filterHelper.getFilter(filterKeyName(filterKeys.active)))
                    )
                    && (
                        (filterHelper.getFilter(filterKeyName(filterKeys.connectivity)) === null)
                        || (x.connectivity === null)
                        || (x.connectivity === filterHelper.getFilter(filterKeyName(filterKeys.connectivity)))
                    )
                )
        },
        reloadApiData,
        filterString
    ]
};