import { getCurrentViewKey } from '../components/Layout/layoutConfig.js';

const searchKeyValue = "view";
let currentViewKey = null;

currentViewKey = getCurrentViewKey(searchKeyValue);

const listeners = {
    list: [],
    emitChange() {
        for (let listener of this.list) {
            listener();
        }
    }
};

export const currentViewHelper = {
    setView(val) {
        currentViewKey = val;
        window.history.pushState({}, '', val ? `?${this.searchKey}=${val}` : '');
        listeners.emitChange();
    },
    subscribe(listener) {
        listeners.list = [...listeners.list, listener];
        return () => {
            listeners.list = listeners.list.filter(l => l !== listener);
        };
    },
    getSnapshot() {
        return currentViewKey;
    },
    get searchKey() {
        return searchKeyValue
    }
};

window.addEventListener("popstate", () => {
    const newViewKey = getCurrentViewKey(searchKeyValue);

    if (newViewKey !== currentViewKey) {
        currentViewKey = newViewKey;
        listeners.emitChange();
    }
});