import React, { useSyncExternalStore } from "react";

import { getViewByKey } from './layoutConfig.js'
import { currentViewHelper } from "../../stores/pageViews.js";
import { useMsalWiliotIDs, useMsalWiliotRight } from '../../utils/customHooks.js';
import { ContentRowStyle } from "./ContentRowStyle.jsx";

export const ContentBody = () => {
    const currentView = useSyncExternalStore(currentViewHelper.subscribe, currentViewHelper.getSnapshot);
    const WiliotRight = useMsalWiliotRight();
    const WiliotIDs = useMsalWiliotIDs();
    
    return (
        <>
            {getViewByKey(WiliotRight, WiliotIDs, currentView)?.jsx()}

            <ContentRowStyle />
        </>
    )
}
