import React, { useState } from "react";

import { Form, Loader, Header } from 'semantic-ui-react'

import { dateToString, groupBy } from '../../utils/helperFunktions.js'

import { translationHelper } from '../../stores/translationDict.js';

import './ConnectivityView.css'

import ReactEcharts from "echarts-for-react";
import { useFilteredData } from "../../utils/customHooks.js";
import { filterHelper, filterKeyName, filterKeys } from "../../stores/filterManagement.js";

export const ConnectivityView = () => {

    const [assetConnectivity] = useFilteredData('/api/view/connectivity', {}, { eventName: ["connectivity"] });

    const [isGroupingActive, setGroupingActive] = useState(true);

    const filterFrom = filterHelper.getFilter(filterKeyName(filterKeys.fromDate));
    const filterTo = filterHelper.getFilter(filterKeyName(filterKeys.toDate));

    const startDate = filterFrom ? new Date(filterFrom) : new Date(new Date().valueOf() - (1000 * 60 * 60));
    const endDate = filterTo ? new Date(filterTo) : new Date(new Date().valueOf() + (1000 * 60 * 5));

    const groupedData = groupBy(
        [...(assetConnectivity.values ?? [])]
            .map(x => {
                if (x.categoryId === '12') {
                    x.assetId = x.gatewayName?.length ? x.gatewayName ?? x.assetId : x.assetId;
                }
                if (x.categoryId === '11') {
                    x.assetId = x.bridgeName?.length ? x.bridgeName ?? x.assetId : x.assetId;
                }
                return x;
            })
            .toSorted((a, b) => (a['assetId'] ?? '').localeCompare(b['assetId'] ?? '')),
        "assetId"
    );

    Object.keys(groupedData)
        .forEach(key => {
            groupedData[key] = groupedData[key]
                .toSorted((a, b) =>
                    (a.start?.valueOf() ?? 0) - (b.start?.valueOf() ?? 0)
                )
                .filter((event, eventIndex, eventArray) => !isGroupingActive || !(
                    eventArray[eventIndex - 1]?.connectivity?.toString() === event.connectivity?.toString()
                    && eventArray[eventIndex + 1]?.connectivity?.toString() === event.connectivity?.toString()
                ));
        });

    const options = {
        xAxis: {
            type: "time",
            splitLine: {
                show: false
            },
            min: startDate,
            max: endDate,
            interval: (1000 * 60 * 5),
        },
        yAxis: {
            type: "value",
            show: false,
        },
        grid: {
            top: 15,
            left: 15,
            right: 15,
            bottom: 15,
            height: 160
        }
    }

    const getColor = x => x ? 'rgba(0, 128, 0, 0.4)' : 'rgba(255, 0, 0, 0.4)';

    return (
        <>
            <Form>
                <Form.Group inline>
                    <Form.Checkbox
                        checked={isGroupingActive}
                        label={translationHelper.translate('groupingCheckboxLabel')}
                        onChange={() => setGroupingActive(!isGroupingActive)}
                    />
                </Form.Group>
            </Form>
            <div className="connectivity_view">
                {
                    (
                        (
                            assetConnectivity.processing &&
                            <Loader active>
                                <Header>
                                    {translationHelper.translate('waitingForData')}
                                </Header>
                            </Loader>
                        )
                        ||
                        (
                            !Object.keys(groupedData).length &&
                            <Header>
                                {translationHelper.translate('noDataHeader')}
                            </Header>
                        )
                    )
                    ||
                    (
                        Object.keys(groupedData)
                            .map(key => (
                                <div key={key} className={"connectivity_cart"}>
                                        <h1>{groupedData[key][0].assetId}</h1>
                                    {
                                        (
                                            groupedData[key][0].categoryId === '12' &&
                                            <h3>({translationHelper.translate('gatewayHeader')})</h3>
                                        )
                                        ||
                                        (
                                            groupedData[key][0].categoryId === '11' &&
                                            <h3>({translationHelper.translate('bridgeHeader')})</h3>
                                        )
                                    }
                                    <ReactEcharts
                                        notMerge={true}
                                        option={{
                                            ...options,
                                            series: [...groupedData[key]]
                                                .map((ele, index) => ({
                                                    name: translationHelper.translate(ele.connectivity ? 'online' : 'offline'),
                                                    type: "line",
                                                    symbol: "none",
                                                    symbolSize: 5,
                                                    sampling: "average",
                                                    itemStyle: {
                                                        color: getColor(ele.connectivity)
                                                    },
                                                    areaStyle: {},
                                                    data: [
                                                        [ele.start, 1],
                                                        [(groupedData[key][index + 1]?.start ?? new Date()), 1],
                                                    ]
                                                }))
                                        }}
                                    />
                                    <div className="status_container">
                                        {
                                            [...groupedData[key]]
                                                .reverse()
                                                .map((ele, i) => (
                                                    <div
                                                        key={key + "_i_" + i}
                                                        className={(ele.connectivity ? "color_green" : "color_red") + " status_element"}
                                                    >
                                                        {translationHelper.translate(ele.connectivity ? 'online' : 'offline')} ({dateToString(ele.start)})
                                                    </div>
                                                ))
                                        }
                                    </div>
                                </div>
                            ))
                    )
                }
            </div>
        </>
    )
}