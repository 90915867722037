import { LogLevel, InteractionRequiredAuthError } from "@azure/msal-browser";

const b2cName = 'dce4c';

const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_wiliot_sign_in',
        forgotPassword: 'B2C_1_wiliot_reset_pass',
        editProfile: 'B2C_1_wiliot_edit',
    },
    authorityDomain: `${b2cName}.b2clogin.com`,
};

b2cPolicies.authorities = {
    signUpSignIn: {
        authority: `https://${b2cName}.b2clogin.com/${b2cName}.onmicrosoft.com/${b2cPolicies.names.signUpSignIn}`,
    },
    forgotPassword: {
        authority: `https://${b2cName}.b2clogin.com/${b2cName}.onmicrosoft.com/${b2cPolicies.names.forgotPassword}`,
    },
    editProfile: {
        authority: `https://${b2cName}.b2clogin.com/${b2cName}.onmicrosoft.com/${b2cPolicies.names.editProfile}`,
    },
}

export { b2cPolicies }

export const msalConfig = {
    auth: {
        clientId: 'e04e0561-79f6-4044-bf0e-02ff7cc79c2b',
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.

    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }	
            }	
        }
    },
};

export const loginRequest = {
    scopes: [
        "openid",
        "offline_access",
        "https://dce4c.onmicrosoft.com/e04e0561-79f6-4044-bf0e-02ff7cc79c2b/access_as_user"
    ]
};

export const compareIssuingPolicy = (idTokenClaims, policyToCompare) => {
    let tfpMatches = idTokenClaims.hasOwnProperty('tfp') && idTokenClaims['tfp'].toLowerCase() === policyToCompare.toLowerCase();
    let acrMatches = idTokenClaims.hasOwnProperty('acr') && idTokenClaims['acr'].toLowerCase() === policyToCompare.toLowerCase();
    return tfpMatches || acrMatches;
}

export const getAccessToken = async (instance) => {
    
    if (instance) {
        const account = instance.getActiveAccount()
        if (account) {
            try {
                const token = await instance.acquireTokenSilent({
                    scopes: [...loginRequest.scopes],
                    account: account,
                });

                return token.accessToken;
            }
            catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    try {
                        const token = await instance.acquireTokenPopup({
                            scopes: [...loginRequest.scopes],
                            account: account,
                        });

                        return token.accessToken;
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
                else {
                    console.log(error)
                }
            }
        }
    }

    return null
}
