import React from "react";
import { useApiGet, useApiPost } from "../../utils/customHooks";
import { TableComp } from "../Layout/TableComp";
import { translationHelper } from '../../stores/translationDict.js';

export const LocationMasterData = () => {
    const classKeys = [
        "id",
        "name",
        "type",
        "ownerID"
    ]
        .map(key => ({
            key,
            get name() { return translationHelper.translate(this.key + '_LocationKey') }
        }));
        
    const [locations,, reloadLocations] = useApiGet('/api/wiliot/locations');
    const [locationPostState] = useApiPost('/api/wiliot/locations');

    return (
        <TableComp
            classKeys={classKeys}
            dataState={locations}
            reloadDataFunc={reloadLocations}
            actionState={locationPostState}
        />
    )
}