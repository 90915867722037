import React, { useState, useSyncExternalStore } from 'react';
import { Menu, Modal, Form, Icon, Button } from 'semantic-ui-react'

import { filterHelper, filterKeys } from '../../stores/filterManagement.js';
import { useApiGet } from '../../utils/customHooks.js';
import { dateToDatetimeLocal, splitCamelCase } from '../../utils/helperFunktions.js';

export const FilterMenu = () => {
    const [open, setOpen] = useState(null);
    const stringFilter = useSyncExternalStore(filterHelper.subscribe, filterHelper.getSnapshot);
    const [filterOptions, , loadFilterOptions] = useApiGet('/api/view/get_filter_options', {}, false);

    return (
        <>
            <Modal
                open={open ?? false}
                onClose={() => { setOpen(false); }}
            >
                <Modal.Header>Filter Menu</Modal.Header>
                <Modal.Content>
                    <Form>
                        {
                            Object
                                .keys(filterKeys)
                                .map(key => (
                                    (
                                        (filterKeys[key].type === 'array') &&
                                        <Form.Dropdown
                                            key={key}
                                            fluid
                                            multiple
                                            search
                                            clearable
                                            selection
                                            label={splitCamelCase(key)?.toUpperCase()}
                                            placeholder={"Value"}
                                            loading={filterOptions.processing}
                                            disabled={filterOptions.processing}
                                            options={
                                                (filterOptions.values?.[key] ?? [])
                                                    .map((x, i) => ({
                                                        key: key + i + x,
                                                        text: x,
                                                        value: x
                                                    }))
                                            }
                                            value={filterHelper.getFilter(key) ?? []}
                                            onChange={(_, { value }) => {
                                                filterHelper.setFilter(key, value)
                                            }}
                                            header={(
                                                <Button
                                                    attached
                                                    fluid
                                                    loading={filterOptions.processing}
                                                    disabled={filterOptions.processing}
                                                    content={
                                                        (filterHelper.getFilter(key) ?? []).length === (filterOptions.values?.[key] ?? []).length ?
                                                            "None"
                                                            :
                                                            "All"
                                                    }
                                                    onClick={() => {
                                                        filterHelper.setFilter(
                                                            key,
                                                            (filterHelper.getFilter(key) ?? []).length === (filterOptions.values?.[key] ?? []).length ?
                                                                []
                                                                :
                                                                (filterOptions.values?.[key] ?? [])
                                                        )
                                                    }}
                                                />
                                                        
                                            )}
                                        />
                                    )
                                    ||
                                    (
                                        (filterKeys[key].type === 'number') &&
                                        <Form.Input
                                            key={key}
                                            label={splitCamelCase(key)?.toUpperCase()}
                                            fluid
                                            type="number"
                                            placeholder={"Value"}
                                            value={filterHelper.getFilter(key) ?? ''}
                                            onChange={(_, { value }) => {
                                                const num = parseFloat(value);
                                                filterHelper.setFilter(key, isNaN(num) ? null : num)
                                            }}
                                        />
                                    )
                                    ||
                                    (
                                        (filterKeys[key].type === 'bool') &&
                                        <Form.Checkbox
                                            key={key}
                                            checked={filterHelper.getFilter(key) ?? undefined}
                                            indeterminate={
                                                filterHelper.getFilter(key) === null
                                                || filterHelper.getFilter(key) === undefined
                                            }
                                            onChange={() => {
                                                filterHelper.setFilter(key,
                                                    (filterHelper.getFilter(key) === false) ?
                                                        true
                                                        :
                                                        (filterHelper.getFilter(key) === true)
                                                            && filterKeys[key].default === null ?
                                                            null
                                                            :
                                                            false
                                                )
                                            }}
                                            label={
                                                splitCamelCase(key)?.toUpperCase()
                                                + ': '
                                                + (
                                                    (filterHelper.getFilter(key) === true) ?
                                                        'yes'
                                                        :
                                                        (filterHelper.getFilter(key) === false) ?
                                                            'no'
                                                            :
                                                            'both'
                                                )
                                            }
                                        />
                                    )
                                    ||
                                    (
                                        (filterKeys[key].type === 'datetime') &&
                                        <Form.Input
                                            key={key}
                                            label={splitCamelCase(key)?.toUpperCase()}
                                            fluid
                                            type="datetime-local"
                                            placeholder={"Value"}
                                            value={filterHelper.getFilter(key) ? dateToDatetimeLocal(new Date(filterHelper.getFilter(key))) : ''}
                                            onChange={(_, { value }) => {
                                                filterHelper.setFilter(key, value ? new Date(value).toJSON() : null)
                                            }}
                                        />
                                    )
                                ))
                        }
                    </Form>
                    <div key={stringFilter}>&nbsp;</div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        negative
                        content={"Reset All Fillter"}
                        onClick={() => {
                            filterHelper.deleteAllFilter()
                        }}
                    />
                </Modal.Actions>
            </Modal>
            <Menu
                compact
            >
                <Menu.Item
                    link
                    onClick={() => {
                        if (
                            open === null
                            && filterOptions.processing === false
                        ) {
                            loadFilterOptions();
                        }
                        setOpen(true);
                    }}
                >
                    <Icon name='filter' />
                </Menu.Item>
            </Menu>
        </>
    )
}