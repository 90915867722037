import React from "react";
import { useApiGet } from "../../utils/customHooks";
import { translationHelper } from "../../stores/translationDict";
import { groupBy } from "../../utils/helperFunktions";
import { Menu, Loader, Header, Table, Grid, Button } from 'semantic-ui-react'

export const ConnectedUsers = () => {
    const [users, , reloadUsers] = useApiGet('/api/data/admin/user');

    const grouped = groupBy((users.values ?? []), "key");

    return (
        <>
            <Menu fluid secondary>
                <Menu.Item>
                    <Button
                        onClick={() => reloadUsers()}
                        content={translationHelper.translate('reloadData')}
                    />
                </Menu.Item>
            </Menu>
            {
            (
                !Object.keys(grouped).length &&
                <Loader active>
                    <Header>
                        {translationHelper.translate('waitingForData')}
                    </Header>
                </Loader>
            )
                ||
                (
                    <Grid celled>
                        {
                            (() => {
                                const array = Object.keys(grouped);
                                const num = 2;
                                const group = [];

                                for (let i = 0; i < array.length; i += num) {
                                    group.push(array.slice(i, i + num));
                                }

                                return group;
                            })()
                                .map((identifireRow, rowIndex) => (
                                    <Grid.Row key={"UserRow" + rowIndex} columns={identifireRow.length}>
                                        {
                                            identifireRow.map(identifire => (
                                                <Grid.Column key={identifire}>
                                                    <Header textAlign='center'>{identifire}</Header>
                                                    <Table singleLine fixed>
                                                        <Table.Body>
                                                            {
                                                                grouped[identifire]
                                                                    .flatMap(obj => (
                                                                        Object.keys(obj)
                                                                            .filter(x => x !== 'key')
                                                                            .map(key => ({key, value: obj[key]}))
                                                                    ))
                                                                    .toSorted((a, b) => (a.key.localeCompare(b.key)))
                                                                    .map(({ key, value}, keyIndex) => (
                                                                        <Table.Row
                                                                            key={"UserRow" + rowIndex + "tableRow" + keyIndex}
                                                                        >
                                                                            <Table.Cell>
                                                                                {key}
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {value}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    ))
                                                            }
                                                        </Table.Body>
                                                    </Table>
                                                </Grid.Column>
                                            ))
                                        }
                                    </Grid.Row>
                                ))
                        }
                    </Grid>
                )
            }
        </>
    )
};