import React from 'react';

import { translationHelper } from '../../stores/translationDict.js';

import { StructuredTableView } from '../DataViews/StruckturedTableView.jsx';

import { TemperatureView } from "../DataViews/TemperaturView.jsx";
import { LocationZoneView } from "../DataViews/LocationZoneView.jsx";
import { ConnectivityView } from "../DataViews/ConnectivityView.jsx";

import { LocationMasterData } from "../MasterData/Locations.jsx";
import { ZoneMasterData } from "../MasterData/Zones.jsx";
import { CategoriesMasterData } from "../MasterData/Categories.jsx";
import { GatewayMasterData } from "../MasterData/Gateways.jsx";
import { BridgesMasterData } from "../MasterData/Bridges.jsx";

import { NotAllowedTemplate } from '../StaticTemplates/NotAllowed.jsx';

import { WiliotAccounts, WiliotRights } from '../../utils/msalWiliotAccount.js';
import { EventStatistic } from '../DataViews/EventStatistic.jsx';
import { AssetMonitor } from '../DataViews/AssetMonitor.jsx';
import { AssetsPerZone } from '../DataViews/AssetsPerZone.jsx';
import { DataExporter } from '../DataViews/DataExporter.jsx';
import { ZoneFlowChecker } from '../DataViews/ZoneFlowChecker.jsx';
import { AssetsMasterData } from '../MasterData/Assets.jsx';
import { DatabaseTable } from '../DataViews/DatabaseTable.jsx';
import { ConnectedUsers } from '../UserManagement/ConnectedUsers.jsx';

const views = {
    LiveData: [
        {
            key: "TableView",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isReader(x),
            jsx: () => (<StructuredTableView />)
        },
    ],
    DataExport: [
        {
            key: "DataExporter",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isReader(x),
            jsx: () => (<DataExporter />)
        },
    ],
    MasterData: [
        {
            key: "LocationMasterData",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isWriter(x),
            jsx: () => (<LocationMasterData />)
        },
        {
            key: "ZoneMasterData",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isWriter(x),
            jsx: () => (<ZoneMasterData />)
        },
        {
            key: "CategoriesMasterData",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isWriter(x),
            jsx: () => (<CategoriesMasterData />)
        },
        {
            key: "AssetsMasterData",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isWriter(x),
            jsx: () => (<AssetsMasterData />)
        },
        {
            key: "BridgesMasterData",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isWriter(x),
            jsx: () => (<BridgesMasterData />)
        },
        {
            key: "GatewayMasterData",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isWriter(x),
            jsx: () => (<GatewayMasterData />)
        },
    ],
    DataView: [
        {
            key: "TemperatureView",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isReader(x),
            jsx: () => (<TemperatureView />)
        },
        {
            key: "LocationZoneView",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isReader(x),
            jsx: () => (<LocationZoneView />)
        },
        {
            key: "ConnectivityView",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isReader(x),
            jsx: () => (<ConnectivityView />)
        },
        {
            key: "DatabaseTable",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isReader(x),
            jsx: () => (<DatabaseTable />)
        },
    ],
    Monitoring: [
        {
            key: "EventStatistic",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isReader(x),
            jsx: () => (<EventStatistic />)
        },
        {
            key: "AssetMonitor",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isReader(x),
            jsx: () => (<AssetMonitor />)
        },
        {
            key: "AssetPerZone",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isReader(x),
            jsx: () => (<AssetsPerZone />)
        },
    ],
    AccountSpecific: [
        {
            key: "ZoneFlowChecker",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isAdmin(x),
            checkAccount: x => WiliotAccounts.containsAccount(x, ['376898445545']),
            jsx: () => (<ZoneFlowChecker />)
        }
    ],
    Administration: [
        {
            key: "UserInformation",
            get name() { return translationHelper.translate(this.key) },
            checkRight: x => WiliotRights.isAdmin(x),
            checkAccount: x => WiliotAccounts.containsAccount(x, ['alle']),
            jsx: () => (<ConnectedUsers />)
        }
    ]
};

export const getViewsObj = (right, accounts) => {
    let v = {};
    Object
        .keys(views)
        .forEach(key => {
            v[key] = views[key].filter(x =>
                (!x.checkRight || x.checkRight(right))
                && (!x.checkAccount || x.checkAccount(accounts))
            )
        })
    return v;
};

export const getViews = (right, accounts) =>
    Object
        .keys(views)
        .flatMap(key => views[key])
        .filter(x =>
            (!x.checkRight || x.checkRight(right))
            && (!x.checkAccount || x.checkAccount(accounts))
        );

export const getViewByKey = (right, accounts, key) => {
    const ele = Object.keys(views).flatMap(key => views[key]).find(x => x.key === key);
    const notAllowedEle = { jsx: () => (<NotAllowedTemplate />) }

    return (
        key === null ?
            (getViews(right, accounts)?.[0] ?? notAllowedEle)
            :
            (
                ele
                && (!ele.checkRight || ele.checkRight(right))
                && (!ele.checkAccount || ele.checkAccount(accounts))
            ) ?
                ele
                :
                notAllowedEle
    );
};

export const getViewName = (right, accounts, key) => {
    return getViews(right, accounts)
        ?.find(ele => key === ele.key || !key)
        ?.name
}

export const getCurrentViewKey = (searchKey) => {
    let viewKey = null;

    try {
        const xurlSearchStr = (new URLSearchParams(window.location.search));
        if (xurlSearchStr.has(searchKey)) {
            viewKey = xurlSearchStr.get(searchKey);
        }
    } catch (error) {
        viewKey = null;
        console.log(error)
    }

    return viewKey;
}