
export const groupBy = (xs, key) =>
    xs.reduce(
        (rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        },
        {}
    );
;

export const dateToString = (date, dateString) => {
    const d = (date ?? new Date(dateString ?? 0));
    
    return ((d.getDate() < 10 ? '0' : '') + d.getDate())
        + '.'
        + (((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1))
        + '.'
        + (d.getFullYear())
        + ' '
        + ((d.getHours() < 10 ? '0' : '') + d.getHours())
        + ':'
        + ((d.getMinutes() < 10 ? '0' : '') + d.getMinutes())
        + ':'
        + ((d.getSeconds() < 10 ? '0' : '') + d.getSeconds())

};

export const dateToDatetimeLocal = (date) => {
    return (date.getFullYear())
        + '-'
        + (((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1))
        + '-'
        + ((date.getDate() < 10 ? '0' : '') + date.getDate())
        + 'T'
        + ((date.getHours() < 10 ? '0' : '') + date.getHours())
        + ':'
        + ((date.getMinutes() < 10 ? '0' : '') + date.getMinutes())
        + ':'
        + ((date.getSeconds() < 10 ? '0' : '') + date.getSeconds())
};

export const splitCamelCase = str => str?.split(/(?=[A-Z])/).join(" ");