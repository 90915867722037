import React from "react";
import { useApiGet, useApiPost } from "../../utils/customHooks";
import { TableComp } from "../Layout/TableComp";
import { translationHelper } from '../../stores/translationDict.js';

export const GatewayMasterData = () => {
    const classKeys = [
        "id",
        "name",
        "type",
        "ownerID"
    ]
        .map(key => ({
            key,
            get name() { return translationHelper.translate(this.key + '_GatewayKey') }
        }));

    const [gateways, , reloadGateways] = useApiGet('/api/wiliot/gateways');
    const [gatewayPostState] = useApiPost('/api/wiliot/gateways');

    return (
        <TableComp
            classKeys={classKeys}
            dataState={gateways}
            reloadDataFunc={reloadGateways}
            actionState={gatewayPostState}
        />
    )
}