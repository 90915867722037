import React, { useSyncExternalStore } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Grid, Image, Header, Menu } from 'semantic-ui-react'

import { loginRequest, msalConfig } from '../../ms_identity/authConfig.js';

import { translationHelper } from '../../stores/translationDict.js';
import { currentViewHelper } from '../../stores/pageViews.js';
import { useMsalWiliotIDs, useMsalWiliotRight } from '../../utils/customHooks.js';
import { getViewName } from "./layoutConfig.js";
import { FilterMenu } from './FilterMenu.jsx';
import { NavigationMenu } from './NavigationMenu.jsx';

export const NavigationRow = () => {
    const { instance } = useMsal();

    const currentView = useSyncExternalStore(currentViewHelper.subscribe, currentViewHelper.getSnapshot);
    const WiliotRight = useMsalWiliotRight();
    const WiliotIDs = useMsalWiliotIDs();
    
    const handleLoginPopup = () => {
        instance.loginPopup(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutPopup = () => {
        const logoutRequest = {
            account: instance.getActiveAccount(),
            postLogoutRedirectUri: msalConfig.auth.redirectUri,
            mainWindowRedirectUri: msalConfig.auth.redirectUri
        };
        instance.logoutPopup(logoutRequest);
    };

    return (
        <>
            <Grid.Row
                verticalAlign='middle'
                textAlign='center'
                id='navigation-row'
            >
                <AuthenticatedTemplate>
                    <Grid.Column>
                        <NavigationMenu />
                    </Grid.Column>
                    <Grid.Column>
                        <FilterMenu />
                    </Grid.Column>
                    <Grid.Column width='8'>
                        <Header as='h3'>
                            {
                                getViewName(WiliotRight, WiliotIDs, currentView)
                            }
                        </Header>
                    </Grid.Column>
                    <Grid.Column width='2'>
                        <Menu compact>
                            <Menu.Item onClick={handleLogoutPopup}>
                                {translationHelper.translate('logout')}
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Grid.Column
                        width='12'
                        textAlign='right'
                    >
                        <Menu compact>
                            <Menu.Item onClick={handleLoginPopup}>
                                {translationHelper.translate('login')}
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                </UnauthenticatedTemplate>
                <Grid.Column
                    width='2'
                >
                    <Image
                        alt={'ecobyte logo'}
                        src={require('../../../public/logo-ecobyte.svg').default}
                        size='small'
                    />
                </Grid.Column>
                <Grid.Column
                    width='2'
                >
                    <Image
                        alt={'dce logo'}
                        src={require('../../../public/dce-icon.svg').default}
                        size='small'
                    />
                </Grid.Column>
            </Grid.Row>
        </>
    );
};