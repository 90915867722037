import React, { useSyncExternalStore, useState } from "react";

import { Form, Loader, Header, Table } from 'semantic-ui-react'

import { groupBy } from '../../utils/helperFunktions.js'

import { wiliotDataHelper } from "../../stores/wiliotLiveData.js";
import { translationHelper } from '../../stores/translationDict.js';

export const EventStatistic = () => {
    const wiliotDataValues = useSyncExternalStore(wiliotDataHelper.subscribe, wiliotDataHelper.getSnapshotValues);
    const [filterText, setFilterText] = useState("");
    const [intervalFilter, setIntervalFilter] = useState(5);

    const data = [...wiliotDataValues]
        .filter(x => x.categoryId?.toString() !== '12' && x.categoryId?.toString() !== '11')
        .filter(x => {
            let filterBool = true
            if (filterText.trim() !== '') {
                try {
                    filterBool = new RegExp(filterText, "i").test(x['assetId'])
                } catch (_) { }
            }

            return filterBool;
        })
        .toSorted((a, b) => (a['assetId'] ?? '').localeCompare(b['assetId'] ?? ''));

    const groupedAssets = groupBy(data, "assetId");

    const statisticObj = {};
    Object
        .keys(groupedAssets)
        .forEach(assetId => {
            const groupedEvents = groupBy(groupedAssets[assetId], "eventName");
            Object
                .keys(groupedEvents)
                .forEach(eventName => {
                    if (!statisticObj[assetId]) {
                        statisticObj[assetId] = {};
                    }
                    statisticObj[assetId][eventName] = groupedEvents[eventName]
                        ?.filter(x => x.start >= new Date(new Date().valueOf() - (1000 * 60 * intervalFilter)))
                        ?.length ?? 0;
                })
        });

    return (
        <>
            <Form>
                <Form.Group inline>
                    <Form.Input
                        placeholder={translationHelper.translate('searchPlaceholder')}
                        label={translationHelper.translate('assetIdLabel')}
                        value={filterText}
                        onChange={({ target: { value } }) => setFilterText(value)}
                    />
                    <Form.Input
                        type='number'
                        label={translationHelper.translate('filterMinutes')}
                        value={intervalFilter}
                        onChange={({ target: { value } }) => setIntervalFilter(value)}
                        max={(60 * 6).toString()}
                        min={'1'}
                    />
                </Form.Group>
            </Form>
            <div className="zonen_view">
                {
                    (
                        !Object.keys(statisticObj).length &&
                        <Loader active>
                            <Header>
                                {translationHelper.translate('waitingForData')}
                            </Header>
                        </Loader>
                    )
                    ||
                    (
                        Object
                            .keys(statisticObj)
                            .map(assetId => (
                                <div key={assetId} className={"zonen_cart"}>
                                    <h1>{assetId}</h1>
                                    <Table celled singleLine>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    Event Name
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Anzahl Interval
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {
                                                Object
                                                    .keys(statisticObj[assetId])
                                                    .map(eventName => (
                                                        <Table.Row key={assetId + eventName}>
                                                            <Table.Cell>
                                                                {eventName}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {statisticObj[assetId][eventName]?.toString()}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))
                                            }
                                        </Table.Body>
                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    Gesamt
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {
                                                        Object
                                                            .keys(statisticObj[assetId])
                                                            .map(eventName => (statisticObj[assetId][eventName]))
                                                            .reduce((ac, cu) => (ac + cu), 0)
                                                    }
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                </div>
                            ))
                    )
                }
            </div>
        </>
    )
}