import React, { useSyncExternalStore } from 'react';
import { useMsal } from '@azure/msal-react';
import { Dropdown, Menu } from 'semantic-ui-react'

import { translationHelper } from '../../stores/translationDict.js';
import { currentViewHelper } from '../../stores/pageViews.js';
import { useMsalWiliotIDs, useMsalWiliotRight } from '../../utils/customHooks.js';
import { getViewsObj } from "./layoutConfig.js";

export const NavigationMenu = () => {
    const { instance } = useMsal();

    const currentView = useSyncExternalStore(currentViewHelper.subscribe, currentViewHelper.getSnapshot);
    const WiliotRight = useMsalWiliotRight();
    const WiliotIDs = useMsalWiliotIDs();

    const menuObj = getViewsObj(WiliotRight, WiliotIDs);

    const user = instance.getActiveAccount();

    return (
        <>
            <Menu compact>
                <Dropdown icon='bars' simple item>
                    <Dropdown.Menu
                        style={{
                            maxHeight: '60vh',
                            overflow: 'hidden auto',
                            paddingRight: '1rem'
                        }}
                    >
                        {
                            (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                            <>
                                <Dropdown.Header>Wiliot ID</Dropdown.Header>
                                <Dropdown.Header>{WiliotIDs?.join(', ')}</Dropdown.Header>
                                <Dropdown.Divider />
                                <Dropdown.Header>Wiliot Recht</Dropdown.Header>
                                <Dropdown.Header>{WiliotRight}</Dropdown.Header>
                                <Dropdown.Divider />
                            </>
                        }
                        {
                            Object
                                .keys(menuObj)
                                .map((menuHeader, i) => (
                                    !!menuObj[menuHeader].length &&
                                    <React.Fragment key={"NavHeaderRow_Dropdown" + i}>
                                        {
                                            (i !== 0) &&
                                            <Dropdown.Divider />
                                        }
                                        <Dropdown.Header>{menuHeader}</Dropdown.Header>
                                        {
                                            menuObj[menuHeader].map(menuEntry => (
                                                <Dropdown.Item
                                                    key={"views_" + menuEntry.key}
                                                    active={(currentView === menuEntry.key)}
                                                    onClick={() => {
                                                        currentViewHelper.setView(menuEntry.key);
                                                    }}
                                                >
                                                    {menuEntry.name}
                                                </Dropdown.Item>
                                            ))
                                        }
                                    </React.Fragment>
                                ))
                        }
                        <Dropdown.Divider />
                        {
                            (
                                user &&
                                <>
                                    <Dropdown.Item disabled>
                                        {
                                            user?.displayName?.length > 0 ?
                                                user?.displayName
                                                :
                                                `${user.name}`
                                        }
                                    </Dropdown.Item>
                                    <Dropdown.Item disabled>{user.username}</Dropdown.Item>
                                </>
                            )
                            ||
                            <Dropdown.Item disabled>{translationHelper.translate('unknownUserName')}</Dropdown.Item>
                        }
                        <div>&nbsp;</div>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu>
        </>
    )
}