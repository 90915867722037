import React from "react";

import { Loader, Header } from 'semantic-ui-react'

import { groupBy } from '../../utils/helperFunktions.js'
import { translationHelper } from '../../stores/translationDict.js';

import ReactEcharts from "echarts-for-react";
import { useFilteredData } from "../../utils/customHooks.js";
import { filterHelper, filterKeyName, filterKeys } from "../../stores/filterManagement.js";
  
export const TemperatureView = () => {

    const [assetTemperaturs] = useFilteredData('/api/view/temperature', {}, { eventName: ['temperature'] });

    const filterFrom = filterHelper.getFilter(filterKeyName(filterKeys.fromDate));
    const filterTo = filterHelper.getFilter(filterKeyName(filterKeys.toDate));

    const startDate = filterFrom ? new Date(filterFrom) : new Date(new Date().valueOf() - (1000 * 60 * 60));
    const endDate = filterTo ? new Date(filterTo) : new Date(new Date().valueOf() + (1000 * 60 * 5));
    
    const groupedData = groupBy(
        [...(assetTemperaturs.values ?? [])].toSorted((a, b) => (a['assetId'] ?? '').localeCompare(b['assetId'] ?? '')),
        "assetId"
    );

    const options = {
        legend: {
            orient: 'vertical',
            right: 'center',
            top: '10'
        },
        grid: {
          left: '3%',
          right: '4%',
          containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            position: function (pt) {
                return [pt[0], '10%'];
            }
        },
        toolbox: {
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                restore: {},
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,
            min: startDate,
            max: endDate,
            interval: (1000 * 60 * 5),

        },
        yAxis: {
            type: 'value',
            boundaryGap: false,
        },
        dataZoom: [
            {
                type: 'slider',
                xAxisIndex: 0,
                filterMode: 'none'
            },
            {
                type: 'slider',
                yAxisIndex: 0,
                filterMode: 'none'
            },
            {
                type: 'inside',
                xAxisIndex: 0,
                filterMode: 'none'
            },
            {
                type: 'inside',
                yAxisIndex: 0,
                filterMode: 'none'
            }
        ]
    };

    return (
        <>
            {
                (
                    assetTemperaturs.processing &&
                    <Loader active>
                        <Header>
                            {translationHelper.translate('waitingForData')}
                        </Header>
                    </Loader>
                )
                ||
                (
                    !Object.keys(groupedData).length &&
                    <Header>
                        {translationHelper.translate('noDataHeader')}
                    </Header>
                )
                ||
                (
                    Object
                        .keys(groupedData)
                        .map(key => (
                            <ReactEcharts
                                key={key}
                                notMerge={true}
                                option={{
                                    ...options,
                                    series: {
                                        name: key,
                                        type: 'line',
                                        showSymbol: false,
                                        encode: {
                                            x: 'start',
                                            y: 'temperature',
                                            itemName: 'start',
                                            tooltip: ['temperature']
                                        },
                                        data: groupedData[key]
                                            .sort(function (a, b) {
                                                if (a.start < b.start) {
                                                    return -1;
                                                }
                                                if (a.start > b.start) {
                                                    return 1;
                                                }
                                                return 0;
                                            })
                                            .map(ele => ([ele["start"], ele["temperature"]]))
                                    }
                                }}
                            />
                        ))
                )
            }
        </>
    )
}