import React, { useSyncExternalStore } from "react";

import { TableComp } from "../Layout/TableComp";
import { wiliotDataHelper } from "../../stores/wiliotLiveData";

export const StructuredTableView = () => {

    const wiliotDataValues = useSyncExternalStore(wiliotDataHelper.subscribe, wiliotDataHelper.getSnapshotValues);
    const wiliotDataKeys = useSyncExternalStore(wiliotDataHelper.subscribe, wiliotDataHelper.getSnapshotKeys);
    
    return (
        <>
            <TableComp
                classKeys={wiliotDataKeys.map(k => ({ key: k, name: k }))}
                dataState={{
                    values: wiliotDataValues
                }}
            />
        </>
    )
}