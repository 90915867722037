import React, { useState } from "react";

import { Loader, Table, Message, Header, Icon, Dropdown, Input, Button, Menu } from 'semantic-ui-react';

import { translationHelper } from '../../stores/translationDict.js';
import { dateToString } from "../../utils/helperFunktions.js";

export const TableComp = ({classKeys, innerTable, dataState, reloadDataFunc, actionState, maxTableRows, fixedSizes}) => {

    const [filterText, setFilterText] = useState("");
    const [filterKey, setFilterKey] = useState("");
    const [sorting, setSorting] = useState({ key: classKeys[0]?.key, asc: true });

    const [filterInterval] = useState(maxTableRows ?? 50);
    const [currentPageNo, setPageNo] = useState(1);

    if (
        classKeys?.length
        && !classKeys?.map(x => x.key).some(x => x === filterKey)
    ) {
        setFilterKey(classKeys[0]?.key)
    }
    if (
        classKeys?.length
        && !classKeys?.map(x => x.key).some(x => x === sorting.key)
    ) {
        setSorting({ key: classKeys[0]?.key, asc: true })
    }
    
    const filteredData = [...(dataState.values ?? [])]
        .filter(x => {
            let filterBool = true
            if (filterText.trim() !== '') {
                try {
                    filterBool = new RegExp(filterText, "i").test(x[filterKey])
                } catch (_) {}
            }

            return filterBool;
        })
        .toSorted((a, b) => parseInt(
            (
                (a[sorting.key] instanceof Date && !isNaN(a[sorting.key]?.valueOf()))
                || (b[sorting.key] instanceof Date && !isNaN(b[sorting.key]?.valueOf()))
            ) ?
                (a[sorting.key]?.valueOf() ?? 0) - (b[sorting.key]?.valueOf() ?? 0)
                :
                (a[sorting.key]?.localeCompare || b[sorting.key]?.localeCompare) ?
                    (a[sorting.key] ?? '').localeCompare(b[sorting.key] ?? '')
                    :
                    (a[sorting.key] ?? 0) - (b[sorting.key] ?? 0)
        ));

    const filteredDataLength = filteredData?.length ?? 0;

    if (sorting.asc === false) {
        filteredData.reverse();
    }

    if (
        (
            filteredDataLength === 0
            && currentPageNo !== 1
        )
        || (
            filteredDataLength > 0
            && filteredDataLength < ((currentPageNo - 1) * filterInterval)
        )
    ) {
        setPageNo(1);
    }

    const getMaxPageNo = () => {
        return (
            parseInt(
                (filteredDataLength - (filteredDataLength % filterInterval)) / filterInterval,
                10
            )
            + ((filteredDataLength % filterInterval) > 0 ? 1 : 0)
        );
    };

    return (
        <>
            <Menu
                fluid
                secondary
            >
                {
                    [
                        actionState?.error,
                        dataState?.error
                    ].map((x, i) => (
                        x &&
                        <Menu.Item key={"error" + i}>
                            <Message
                                negative
                                header={translationHelper.translate('errorHeader')}
                                content={x?.message}
                            />
                        </Menu.Item>
                    ))
                }
                {
                    reloadDataFunc &&
                    <Menu.Item>
                        <Button
                            onClick={() => reloadDataFunc()}
                            content={translationHelper.translate('reloadData')}
                        />
                    </Menu.Item>
                }
                <Menu.Item>
                    <Button.Group>
                        <Button
                            onClick={() => {
                                let i = currentPageNo - 1;
                                setPageNo(i < 1 ? 1 : i)
                            }}
                            icon='left arrow'
                        />
                        <Button
                            onClick={() => {
                                let i = currentPageNo + 1;
                                setPageNo(i < getMaxPageNo() ? i : getMaxPageNo())
                            }}
                            icon='right arrow'
                        />
                    </Button.Group>
                </Menu.Item>
                <Menu.Item>
                    {translationHelper.translate('rows')} {filteredDataLength.toString()}
                </Menu.Item>
                <Menu.Item>
                    {translationHelper.translate('page')} {currentPageNo} / {getMaxPageNo()}
                </Menu.Item>
                <Menu.Item fitted='vertically'>
                    <Input
                        type="number"
                        value={currentPageNo}
                        onChange={(ele) => {
                            let i = ele?.target?.value;
                            setPageNo(parseInt(i < 1 ? 1 : (i > getMaxPageNo() ? getMaxPageNo() : i)))
                        }}
                    />
                </Menu.Item>
                {
                    !!classKeys?.length &&
                    <Menu.Item fitted='vertically'>
                        <Input
                            placeholder={translationHelper.translate('searchPlaceholder')}
                            value={filterText}
                            onChange={({ target: { value } }) => setFilterText(value)}
                            label={
                                <Dropdown
                                    value={filterKey}
                                    options={classKeys.map(x => ({ key: x.key, text: x.name, value: x.key }))}
                                    onChange={(_, { value }) => { setFilterKey(value) }}
                                />
                            }
                            labelPosition='right'
                        />
                    </Menu.Item>
                }
            </Menu>
            <Table
                celled
                singleLine
                fixed={!!fixedSizes}
            >
                <Table.Header>
                    <Table.Row>
                        {
                            classKeys.map(({ key, name }) => (
                                <Table.HeaderCell
                                    onClick={() => setSorting({ key, asc: key !== sorting.key ? true : !sorting.asc })}
                                    key={"Header_TableComp_" + key}
                                    {...{
                                        ...(fixedSizes?.[key] && { style: { width: fixedSizes[key] } })
                                    }}
                                >
                                    {name}
                                    {
                                        (
                                            sorting.key === key &&
                                            <Icon name={sorting.asc ? "sort up" : "sort down"} />
                                        )
                                        ||
                                        <Icon name='sort' disabled />
                                    }
                                </Table.HeaderCell>
                            ))
                        }
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        (
                            (
                                dataState.processing
                                || actionState?.processing
                            )
                            &&
                            <Table.Row>
                                <Table.Cell textAlign={'center'} colSpan={classKeys?.length ?? 1}>
                                    <Loader active inline='centered' />
                                </Table.Cell>
                            </Table.Row>
                        )
                        ||
                        (
                            filteredDataLength &&
                            filteredData
                                .filter((_, i) =>
                                    ((currentPageNo - 1) * filterInterval) <= i
                                    && i < (((currentPageNo - 1) * filterInterval) + filterInterval)
                                )
                                .map((x, i) => (
                                    <React.Fragment key={"row_fragment_" + i}>
                                        <Table.Row>
                                            {
                                                classKeys.map(({ key }) => (
                                                    <Table.Cell
                                                        key={"row_" + i + "cell" + key}
                                                        {...{
                                                            ...(fixedSizes?.[key] && { style: { width: fixedSizes[key] } })
                                                        }}
                                                    >
                                                        {
                                                            x[key] instanceof Date && !isNaN(x[key]?.valueOf()) ?
                                                                dateToString(x[key])
                                                                :
                                                                x[key]?.toString()
                                                        }
                                                    </Table.Cell>
                                                ))
                                            }
                                            {
                                                //<Table.Cell>
                                                //    <Input
                                                //        fluid
                                                //        placeholder={"Wiliot Recht"}
                                                //        value={x.extension_f834b2f63cb7406799b53bc94ea151b8_Wiliot_Recht ?? ""}
                                                //        onChange={({ target: { value } }) => {
                                                //            setUser(filteredData.map(u => {
                                                //                if (u.id === x.id) {
                                                //                    u.extension_f834b2f63cb7406799b53bc94ea151b8_Wiliot_Recht = value;
                                                //                }
                                                //                return u
                                                //            }))
                                                //        }}
                                                //    />
                                                //</Table.Cell>
                                                //<Table.Cell>
                                                //    <Button
                                                //        primary
                                                //        loading={apiState.apiEndpoint === `/users/${x.id}`}
                                                //        onClick={async () => {
                                                //            await doPost(
                                                //                `/users/${x.id}`,
                                                //                filteredUsers.find(u => u.id === x.id)
                                                //            )
                                                //            await reloadUserData();
                                                //        }}
                                                //    >
                                                //        Speichern
                                                //    </Button>
                                                //</Table.Cell>
                                            }
                                        </Table.Row>
                                        {
                                            innerTable &&
                                            <Table.Row>
                                                <Table.Cell colSpan={classKeys?.length ?? 1}>
                                                    <InnerTableComp
                                                        innerTable={innerTable}
                                                        values={x[innerTable.key]}
                                                        keyPrefix={"inner" + i + "_"}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        }
                                    </React.Fragment>
                                ))
                        )
                        ||
                        <Table.Row>
                            <Table.Cell textAlign={'center'} colSpan={classKeys?.length ?? 1}>
                                <Header>
                                    {translationHelper.translate('noDataHeader')}
                                </Header>
                            </Table.Cell>
                        </Table.Row>
                    }
                </Table.Body>
            </Table>
        </>
    )
}

const InnerTableComp = ({ innerTable: { innerKeys }, values, keyPrefix }) => {

    const [sorting, setSorting] = useState({ key: innerKeys[0]?.key, asc: true });
    if (
        innerKeys?.length
        && !innerKeys?.map(x => x.key).some(x => x === sorting.key)
    ) {
        setSorting({ key: innerKeys[0]?.key, asc: true })
    }

    const filteredData = [...(values ?? [])]
        .toSorted((a, b) =>
            a[sorting.key]?.localeCompare ?
                a[sorting.key].localeCompare(b[sorting.key])
                :
                a[sorting.key] - b[sorting.key]
        );

    if (sorting.asc === false) {
        filteredData.reverse();
    }

    return (
        <>
            <Table
                celled
                singleLine
            >
                <Table.Header>
                    <Table.Row>
                        {
                            innerKeys
                                .map(({ key, name }) => (
                                    <Table.HeaderCell
                                        onClick={() => setSorting({ key, asc: key !== sorting.key ? true : !sorting.asc })}
                                        key={keyPrefix + key}
                                    >
                                        {name}
                                        {
                                            (
                                                sorting.key === key &&
                                                <Icon name={sorting.asc ? "sort up" : "sort down"} />
                                            )
                                            ||
                                            <Icon name='sort' disabled />
                                        }
                                    </Table.HeaderCell>
                                ))
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        (
                            !!filteredData.length &&
                            filteredData.map((x,i) => (
                                <Table.Row key={keyPrefix + "row_" + i}>
                                    {
                                        innerKeys.map(({ key }) => (
                                            <Table.Cell
                                                key={keyPrefix + "cell" + key + i}
                                            >
                                                {x[key]}
                                            </Table.Cell>
                                        ))
                                    }
                                </Table.Row>
                            ))
                        )
                        ||
                        <Table.Row>
                            <Table.Cell textAlign={'center'} colSpan={innerKeys?.length ?? 1}>
                                <Header>
                                    {translationHelper.translate('noDataHeader')}
                                </Header>
                            </Table.Cell>
                        </Table.Row>
                    }
                </Table.Body>
            </Table>
        </>
    );
};